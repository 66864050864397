<script>
    export let width = 211;
    export let height = 161;
    export let error; // Should be an Error derived instance
    export let source = ''; // Nearest parent dataCy
    export let messageId = null;
    export let fixId = null; // Not used for inline
    export let inline = false;
    export let displayErrorCode = true;
    export let assetType = '';

    import { DriveError, getPropValue } from '../rest.sdks';
    import { i18n } from '../i18n';
    import { trackEvent, categories } from '../providers/analytics/ga';
    import { goto } from '../providers/navigation';
    import { authenticated, checkingSignin } from '../providers/state/user';

    const isNotFoundError = () => {
        if (error instanceof DriveError) {
            const httpStatusCode = getPropValue(error, 'response.status');
            const httpErrorBody = getPropValue(error, 'body') || '';
            const { backendErrorCode } = error;
            if (
                // If valid encoded id but asset does not exist or unauthorized access
                // Drive_Get_Folder_Items / Drive_Get_Private_Shared_With_Me
                [404, 403].includes(httpStatusCode) ||
                // If invalid encoded asset ID, DriveMS returns 400
                (assetType === 'folder' &&
                    httpErrorBody.includes("Received invalid 'FOLDER' id")) ||
                // Drive_Get_Items succeeds when item is not found
                getPropValue(error, 'error.Drive_Get_Items') === 404 ||
                // DriveMS Error Codes - https://wiki.autodesk.com/display/FCPA/Drive+Micro+Service+Error+Codes
                [
                    704, // NOT_FOUND
                    705, // INVALID_URN
                    721, // INVALID_COLLECTION_ID
                ].includes(backendErrorCode)
            ) {
                return true;
            }
        } else if (error instanceof Error) {
            if (
                error.message.startsWith('Invalid Page') ||
                error.message.startsWith('File not found')
            ) {
                return true;
            }
        }
        return false;
    };
    let notFound = isNotFoundError();

    // Track the underlying error
    const errorCode = DriveError.logError(
        notFound && !inline ? `${assetType}-not-found` : source,
        error
    );

    // Fusion returns 400 for non-existing public shares, and DMS just act as proxy and passes it back to us
    // https://autodesk.slack.com/archives/C70F83DC1/p1648054305861479
    // Long term fix should be - either fix Fusion or DMS to return 404
    // 400 error from Drive_Get_Public_Share_By_Id
    if (errorCode === 2177400) {
        notFound = true;
    }

    const dataCy = notFound ? 'not-found' : 'something-wrong';
    const dataCyGoHome = 'go-back-to-home';
    const dataCyGoRoot = 'go-back-to-root';

    // Use custom message (if passed or defined), otherwise use the default
    const customMsgId = notFound
        ? 'App.Errors.Request.Not_Found_Msg'
        : messageId || `App.Errors.Request.${errorCode}_Msg`;
    const defaultMsgId = 'App.Errors.Something_Wrong_Msg';
    let message = i18n.find(customMsgId, defaultMsgId);
    let label = message.id;
    if (displayErrorCode) {
        message.translation += ` [${errorCode.toString(36)}]`;
    }

    let fix;
    if (!inline) {
        // Use custom fix (if passed or defined), otherwise use the default
        const customFixId = notFound
            ? 'App.Errors.Request.Not_Found_Fix'
            : fixId || `App.Errors.Request.${errorCode}_Fix`;
        const defaultFixId = 'App.Errors.Refresh_Browser_Msg';
        fix = i18n.find(customFixId, defaultFixId);
        label += ` ${fix.id}`;
    }

    if (notFound) {
        const pathAccessed = window.location.pathname;
        label += ` Path_Accessed: ${pathAccessed}`;
    }

    // If error is already logged, track the user experience as warning
    // this is to avoid duplicate events under Error-Drive category
    trackEvent({
        category: error.logged ? categories.warnDrive : categories.errorDrive, // 150 Bytes
        action: dataCy, // 500 Bytes
        label, // 500 Bytes
        errorCode: errorCode.toString(), // Custom Dimension
    });
</script>

{#if inline}
    <div class="notification is-light" data-cy={dataCy}>
        {message.translation}
    </div>
{:else}
    <div class="section has-centered-content" data-cy={dataCy}>
        <svg
            {width}
            {height}
            viewBox="0 0 {width} {height}"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M100.622 76.5712C101.601 80.7529 108.585 83.3554 114.469
                85.7799C120.352 88.2044 132.419 90.7958 140.226 89.4945C144.898
                88.716 147.622 87.0923 152.227 86.525C158.9 85.6909 158.588
                88.2934 154.829 89.4056C151.07 90.5177 149.013 90.8736 146.177
                92.6642C142.929 94.6994 139.203 99.1259 141.161 102.051C143.118
                104.976 147.834 103.819 149.435 104.186C152.316 104.842 155.463
                107.211 157.988 108.19C160.868 109.302 175.782 113.951 179.975
                114.785C182.919 115.469 185.63 116.915 187.838 118.978C190.441
                121.391 195.045 122.782 197.692 123.338C200.339 123.894 204.109
                124.828 206.756 125.384C209.403 125.94 210.148 128.32 206.756
                130.133C203.364 131.946 199.66 132.279 195.835 133.614C191.697
                135.06 191.931 138.875 194.856 141.099C197.781 143.323 207.501
                149.518 208.613 152.221C209.726 154.923 209.637 157.526 203.787
                158.638C197.937 159.75 194.155 159.616 190.252 157.526C186.348
                155.435 167.586 150.074 165.361 149.095C163.137 148.117 157.276
                148.962 153.795 147.283C150.314 145.603 150.025 142.4 146.254
                141.722C142.484 141.043 137.357 141.722 133.843 142C130.328
                142.278 123.522 141.444 123.666 136.439C123.811 131.434 123.199
                124.628 113.301 123.749C107.938 123.219 102.541 123.126 97.1634
                123.471C94.0939 123.605 93.4488 126.441 90.8908 126.719C88.3329
                126.997 84.3847 125.284 81.5932 125.417C77.5797 125.754 73.6738
                126.888 70.1045 128.754C65.9672 130.856 60.5955 134.826 55.4351
                135.938C50.2746 137.051 47.8501 136.172 51.6648 133.247C57.8484
                128.498 60.8291 129.988 65.1887 126.685C67.869 124.672 64.3324
                119.868 66.679 116.876C69.4261 113.395 68.7254 109.814 66.5901
                109.536C63.9431 109.191 61.4296 108.134 62.1414 105.632C62.8532
                103.13 62.5529 100.472 58.5158 100.605C54.4786 100.739 39.3532
                100.605 36.8508 99.4929C34.3484 98.3807 32.569 92.8199 40.1873
                91.7077C42.4083 91.3855 44.5186 90.5317 46.3387 89.2189C48.1589
                87.9061 49.635 86.173 50.6416 84.1673L97.5082 70.8213C97.5082
                70.8213 99.499 71.8334 100.622 76.5712Z"
                fill="#8CA9B7"
            />
            <path
                d="M97.5193 70.7219C97.5193 70.7219 99.4656 71.8341 100.589
                76.5719C101.568 80.7536 108.552 83.3561 114.435 85.7806C121.2
                88.3155 128.344 89.688 135.566 89.84C137.123 89.8557 138.678
                89.7404 140.215 89.4952C144.886 88.7167 147.611 87.093 152.216
                86.5258C152.986 86.4233 153.763 86.3676 154.54 86.3589C158.755
                86.3589 158.077 88.4387 154.818 89.4063C151.048 90.5184 149.001
                90.8743 146.165 92.6649C142.918 94.7002 139.192 99.1266 141.15
                102.052C142.395 103.92 144.742 104.12 146.71
                104.12H148.278C148.651 104.109 149.023 104.136 149.391
                104.198C152.271 104.854 155.419 107.223 157.943 108.202C160.824
                109.314 175.738 113.963 179.931 114.797C182.871 115.482 185.582
                116.924 187.794 118.979C190.396 121.403 195.001 122.793 197.647
                123.35C200.294 123.906 204.065 124.84 206.712 125.396C209.359
                125.952 210.104 128.332 206.712 130.145C203.319 131.958 199.616
                132.291 195.79 133.626C191.653 135.072 191.886 138.886 194.811
                141.111C197.736 143.335 207.457 149.53 208.569 152.232C209.681
                154.935 209.592 157.537 203.742 158.65C201.724 159.052 199.672
                159.268 197.614 159.295C195.045 159.341 192.509 158.72 190.252
                157.493C186.348 155.402 167.519 150.097 165.283 149.118C163.048
                148.14 157.198 148.985 153.717 147.306C150.236 145.626 149.947
                142.423 146.176 141.745C144.902 141.527 143.61 141.427 142.317
                141.444C139.337 141.444 136.156 141.823 133.765 142.023C133.387
                142.023 132.975 142.023 132.53 142.023C128.882 142.023 123.466
                140.911 123.633 136.395C123.766 131.379 123.166 124.584 113.268
                123.705C109.119 123.338 105.938 123.227 103.414 123.227C100.689
                123.227 98.7316 123.35 97.1301 123.427C94.0605 123.561 93.4154
                126.397 90.8575 126.675C90.6611 126.686 90.4643 126.686 90.268
                126.675C87.8435 126.675 84.4403 125.34 81.8934
                125.34H81.5598C77.5463 125.677 73.6404 126.811 70.0711
                128.677C65.9672 130.857 60.5955 134.827 55.435 135.939C54.2531
                136.218 53.0456 136.374 51.8316 136.406C49.4071 136.406 48.9066
                135.361 51.6648 133.248C57.8484 128.499 60.829 129.989 65.1887
                126.686C67.869 124.673 64.3323 119.868 66.679 116.877C69.4261
                113.396 68.7254 109.814 66.59 109.536C63.9431 109.192 61.4296
                108.135 62.1414 105.633C62.8532 103.13 62.564 100.606 58.8049
                100.606H58.5046C57.6927 100.606 56.436 100.606 54.9346
                100.606C48.9178 100.606 38.8416 100.339 36.8397 99.4936C34.3262
                98.3814 32.5578 92.8206 40.1762 91.7084C42.3971 91.3863 44.5074
                90.5324 46.3276 89.2196C48.1477 87.9068 49.6239 86.1738 50.6305
                84.168L97.4971 70.822L97.5193 70.7219ZM97.675 69.3206L97.1189
                69.4763L50.2524 82.8223L49.6184 83.0002L49.3626 83.6119C48.4514
                85.3981 47.122 86.9377 45.4876 88.0995C43.8533 89.2614 41.9624
                90.0111 39.976 90.2849C34.6487 91.0523 33.5032 93.7771 33.303
                95.323C33.1718 96.4173 33.3825 97.5254 33.9062 98.4951C34.4299
                99.4649 35.241 100.249 36.228 100.739C38.9639 101.963 52.2542
                102.018 54.8901 102.018C56.3693 102.018 57.6482 102.018 58.5046
                102.018H58.7604C59.1087 101.978 59.4615 102.011 59.7965
                102.114C60.1315 102.218 60.4414 102.39 60.7067 102.619C60.8888
                103.038 60.9828 103.491 60.9828 103.948C60.9828 104.405 60.8888
                104.857 60.7067 105.277C60.5398 105.804 60.4911 106.361 60.5642
                106.909C60.6372 107.457 60.8302 107.982 61.1293 108.447C62.0079
                109.759 63.754 110.593 66.3342 110.938C66.4121 110.938 66.6679
                110.938 66.857 111.438C67.2351 112.384 66.9793 114.185 65.5112
                116.043C64.0432 117.9 64.3212 120.358 64.5881 122.271C64.8551
                124.184 64.9107 125.118 64.2767 125.607C62.7553 126.719 61.0275
                127.517 59.1942 127.954C56.119 128.769 53.2427 130.203 50.7417
                132.169C49.4961 133.137 47.8946 134.593 48.4395 136.195C48.8065
                137.307 49.9187 137.83 51.776 137.83C53.0875 137.799 54.3921
                137.632 55.6686 137.329C59.5945 136.484 63.4537 134.171 66.9682
                132.136C68.2917 131.357 69.5373 130.634 70.6606 130.045C74.0497
                128.268 77.7584 127.183 81.5709 126.853H81.949C83.2948 126.931
                84.629 127.147 85.9306 127.498C87.3841 127.888 88.8757 128.12
                90.3792 128.187C90.635 128.187 90.8908 128.187 91.1133
                128.187C92.4736 127.978 93.7236 127.316 94.6611 126.308C95.3395
                125.535 96.2848 125.046 97.308 124.94H98.2534C99.6324 124.862
                101.345 124.784 103.525 124.784C106.483 124.784 109.664 124.94
                113.256 125.251C121.92 126.03 122.499 131.479 122.354
                136.529C122.319 137.309 122.445 138.087 122.725 138.816C123.004
                139.545 123.431 140.209 123.978 140.766C126.447 143.302 130.884
                143.58 132.686 143.58C133.153 143.58 133.609 143.58 134.032
                143.58L135.989 143.413C138.013 143.224 140.304 143.013 142.473
                143.013C143.683 142.997 144.893 143.086 146.088 143.279C147.477
                143.598 148.692 144.438 149.48 145.626C150.503 146.929 151.797
                147.996 153.272 148.751C155.496 149.864 158.388 149.975 160.935
                150.097C162.267 150.087 163.594 150.252 164.883 150.586C165.728
                150.953 168.22 151.699 172.513 153.055C178.796 154.979 187.382
                157.638 189.751 158.905C192.212 160.237 194.972 160.915 197.77
                160.874C199.93 160.84 202.083 160.602 204.198 160.162C207.346
                159.562 209.314 158.461 210.204 156.826C210.565 156.044 210.741
                155.189 210.715 154.329C210.69 153.468 210.466 152.625 210.059
                151.865C209.125 149.641 204.643 146.405 198.938 142.39C197.67
                141.511 196.58 140.755 195.901 140.232C195.393 139.913 194.977
                139.465 194.696 138.934C194.415 138.403 194.278 137.807 194.3
                137.207C194.478 136.095 195.59 135.472 196.524 135.161C197.926
                134.671 199.36 134.315 200.739 133.971C203.136 133.473 205.461
                132.673 207.657 131.591C210.638 129.978 210.693 128.032 210.582
                127.264C210.404 126.481 209.988 125.771 209.39 125.234C208.793
                124.696 208.044 124.356 207.245 124.261C205.933 123.983 204.309
                123.605 202.797 123.249C201.284 122.894 199.572 122.493 198.237
                122.215C195.201 121.581 191.186 120.18 189.039 118.189C186.645
                115.948 183.697 114.385 180.498 113.663C176.416 112.851 161.736
                108.28 158.744 107.123C157.679 106.667 156.643 106.147 155.641
                105.566C153.833 104.377 151.863 103.455 149.791 102.83C149.314
                102.743 148.83 102.706 148.345 102.719H146.777C144.986 102.719
                143.218 102.541 142.328 101.273C142.177 101.026 142.077 100.751
                142.034 100.464C141.992 100.177 142.009 99.8849 142.084
                99.6048C142.529 97.5362 144.864 95.1561 146.933 93.8216C148.901
                92.6441 151.045 91.7881 153.283 91.2858C153.884 91.119 154.529
                90.941 155.23 90.7297C157.732 89.9846 159.089 88.6611 158.877
                87.1708C158.733 86.1587 157.865 84.9465 154.562 84.9465C153.725
                84.9514 152.889 85.0108 152.06 85.1244C149.834 85.4298 147.643
                85.9551 145.52 86.6926C143.703 87.2961 141.845 87.7682 139.96
                88.105C138.488 88.3315 137 88.4394 135.511 88.4276C128.478
                88.2792 121.521 86.9405 114.936 84.4683L113.824 84.0234C108.841
                81.9881 102.702 79.4524 101.89 76.2382C100.678 71.0556 98.4202
                69.632 98.1644 69.4874L97.6528 69.1982L97.675 69.3206Z"
                fill="#5A7D91"
            />
            <path
                opacity="0.3"
                d="M130.506 76.7948C118.762 70.7224 92.993 58.3551 92.993
                58.3551L28.3763 48.4902L16.3316 75.2711L19.8905 78.4074C20.0265
                78.5305 20.1351 78.6806 20.2095 78.8483C20.2839 79.0159 20.3223
                79.1972 20.3223 79.3806C20.3223 79.564 20.2839 79.7453 20.2095
                79.9129C20.1351 80.0805 20.0265 80.2307 19.8905 80.3537C18.2556
                81.8885 15.0748 84.9136 12.9506 87.1713C9.95886 90.341 11.6382
                101.118 15.5419 104.655C19.4456 108.191 26.3299 112.095 29.4885
                114.141C32.647 116.188 43.9466 116.188 47.8948 115.064C51.8429
                113.941 60.6402 110.794 61.6634 109.504C62.6866 108.213 62.7755
                106.345 64.6328 106.99C66.4902 107.635 66.7348 108.87 70.1937
                108.48C74.2419 108.024 119.129 90.341 130.784 78.6632C130.928
                78.5409 131.039 78.3838 131.106 78.2068C131.172 78.0298 131.192
                77.8388 131.165 77.6518C131.137 77.4647 131.062 77.2879 130.946
                77.138C130.831 76.988 130.68 76.8699 130.506 76.7948ZM56.4695
                105.188C53.5112 107.413 46.7381 111.65 39.4423 110.327C29.6775
                108.558 17.199 94.0556 19.8238 90.5189C20.6064 89.4703 21.4533
                88.4711 22.3595 87.5272C23.5155 86.2835 25.0746 85.4895 26.7603
                85.2859C28.446 85.0823 30.1493 85.4824 31.5682 86.415L56.3695
                102.831C56.5636 102.956 56.7248 103.126 56.8396 103.327C56.9544
                103.527 57.0195 103.753 57.0293 103.984C57.0391 104.215 56.9934
                104.444 56.896 104.654C56.7986 104.864 56.6524 105.047 56.4695
                105.188Z"
                fill="#5A7D91"
            />
            <path
                d="M45.1143 2.79088C38.5549 0.883507 31.588 0.883507 25.0286
                2.79088C18.8228 4.5481 10.0589 13.4454 6.62231 20.2741C2.66301
                28.0593 0.0160574 40.5377 2.17366 47.7001C3.55274 52.36 10.7707
                60.1118 10.7707 60.1118C10.7707 60.1118 9.2359 63.8821 6.58895
                70.9999C3.942 78.1178 4.95406 84.1568 8.7243 92.131C12.4945
                100.105 25.4735 108.469 29.544 110.27C34.3819 112.406 41.3107
                109.703 45.448 105.9C47.4387 104.065 53.6557 97.5139 55.9913
                95.0449C56.2765 94.7405 56.6209 94.4976 57.0034 94.3312C57.3859
                94.1648 57.7985 94.0784 58.2156 94.0773H63.643L96.1293
                67.8525L101.69 23.3659C101.69 23.3659 48.6732 3.88081 45.1143
                2.79088ZM46.004 86.5924C44.717 88.9085 43.1684 91.0691 41.3886
                93.0319C35.9056 98.2368 31.5348 100.094 26.797 97.7697C24.1689
                96.3669 21.6779 94.7211 19.3566 92.8539C18.0896 91.8796 17.089
                90.6012 16.4474 89.1373C15.8059 87.6734 15.5441 86.0713 15.6864
                84.4793C15.9396 82.3522 16.356 80.2477 16.9321 78.1845C17.3428
                76.5581 18.1017 75.0403 19.1564 73.7358C19.4567 73.3688 19.757
                73.024 20.0016 72.7794C20.7979 71.9816 21.8426 71.4795 22.963
                71.3561C24.0834 71.2327 25.2123 71.4955 26.163 72.1009L45.4368
                84.3348C45.6246 84.4436 45.7886 84.589 45.9192 84.7623C46.0498
                84.9356 46.1443 85.1334 46.1972 85.3438C46.2501 85.5543 46.2602
                85.7732 46.227 85.9877C46.1939 86.2021 46.118 86.4078 46.004
                86.5924Z"
                fill="white"
            />
            <path
                d="M56.8253 76.0158C56.8253 76.0158 9.40261 48.9568 6.05499
                46.866C4.128 45.6306 2.5321 43.9431 1.40615 41.9502C1.39449
                43.877 1.64517 45.7964 2.1513 47.6556C3.55262 52.36 10.7706
                60.1118 10.7706 60.1118V60.312C10.8021 60.2646 10.8247 60.2119
                10.8373 60.1563C10.9823 59.7256 11.0275 59.2675 10.9697
                58.8167C10.912 58.3659 10.7526 57.9341 10.5036 57.5539C11.2488
                57.0423 12.4166 57.932 13.2062 58.3991C13.9958 58.8662 15.7642
                60.2119 16.2758 60.6234C16.6477 60.8188 16.9469 61.1286 17.1292
                61.5072C17.3114 61.8857 17.3671 62.3128 17.2879 62.7254C18.1152
                62.7326 18.9184 63.0055 19.5789 63.5039C20.1684 63.9266 36.3392
                74.0472 46.2708 80.4088C46.9159 79.6526 48.5953 80.3199 50.219
                81.2207C52.3099 82.3329 54.4341 83.6119 53.2107 84.8575C53.9114
                85.1911 56.8809 84.4349 56.8809 84.4349C56.8809 84.4349 56.5472
                76.8499 56.8253 76.0158Z"
                fill="#E7F0F5"
            />
            <path
                d="M44.5137 78.1066L19.4121 62.7254C19.1633 62.5706 18.8864
                62.4665 18.5972 62.419C18.3081 62.3716 18.0123 62.3817 17.7271
                62.4489C17.4419 62.5161 17.1727 62.6389 16.9351 62.8104C16.6975
                62.982 16.4961 63.1987 16.3426 63.4483C11.6159 71.0666 7.44526
                81.8658 17.6994 91.2524C16.1896 89.3468 15.469 86.9342 15.6864
                84.5127C15.9395 82.3856 16.3559 80.2811 16.932 78.2179C17.3428
                76.5915 18.1017 75.0736 19.1564 73.7692C19.4566 73.4022 19.7569
                73.0574 20.0016 72.8127C20.7978 72.0149 21.8425 71.5128 22.9629
                71.3895C24.0833 71.2661 25.2122 71.5289 26.163 72.1343L45.4368
                84.3681C45.6967 84.524 45.9082 84.749 46.0476 85.018C46.187
                85.2871 46.2489 85.5896 46.2264 85.8918L46.3599 81.2986C46.338
                80.6558 46.1581 80.0283 45.8361 79.4716C45.5141 78.9149 45.0599
                78.4461 44.5137 78.1066Z"
                fill="#CCD9E1"
            />
            <path
                d="M60.729 83.8237H55.3016C54.8852 83.8227 54.473 83.9071
                54.0904 84.0716C53.7078 84.2361 53.363 84.4772 53.0773
                84.7802C51.9651 86.0147 52.4434 88.1167 48.7287 91.9425C45.8594
                94.912 41.3106 98.838 40.3097 99.7277C36.1835 103.609 28.7209
                105.722 24.428 103.687C18.8138 100.769 13.6395 97.0736 9.05786
                92.7099C13.1729 100.495 25.6069 108.492 29.5773 110.249C34.4152
                112.384 41.344 109.682 45.4812 105.878C47.472 104.043 53.689
                97.4922 56.0245 95.0232C56.3097 94.7188 56.6542 94.4759 57.0367
                94.3095C57.4192 94.1431 57.8317 94.0567 58.2489
                94.0556H63.6762L60.729 83.8237Z"
                fill="#CCD9E1"
            />
            <path
                d="M10.7706 60.3117C10.8021 60.2643 10.8247 60.2116 10.8373
                60.156C10.9822 59.7253 11.0275 59.2672 10.9697 58.8164C10.9119
                58.3656 10.7526 57.9338 10.5036 57.5536C7.29267 54.539 4.47516
                51.1311 2.11792 47.4106L2.18465 47.6998C3.55261 52.3598 10.7706
                60.1116 10.7706 60.1116V60.3117Z"
                fill="#CCD9E1"
            />
            <path
                d="M79.7804 97.2364L92.904 89.6625C104.637 79.4973 112.623
                62.2032 113.156 44.3307C113.713 25.9689 98.2868 16.0373 82.4941
                25.2682C66.7014 34.4992 55.5575 56.4088 55.3462 74.7707C55.146
                91.7645 65.7004 101.463 79.7804 97.2364Z"
                fill="#CCD9E1"
            />
            <path
                d="M108.897 46.8224C108.341 65.4178 98.298 83.268 84.5294
                90.1968C70.7608 97.1256 59.6169 88.7065 59.806 72.7136C59.9839
                57.1433 69.4595 38.5368 82.8611 30.6849C96.2627 22.833 109.364
                31.241 108.897 46.8224Z"
                fill="#B2C4D0"
            />
            <path
                d="M108.897 46.8224C108.341 65.4178 98.298 83.268 84.5294
                90.1968C70.7608 97.1256 59.6169 88.7065 59.806 72.7136C59.9839
                57.1433 69.4595 38.5368 82.8611 30.6849C96.2627 22.833 109.364
                31.241 108.897 46.8224Z"
                stroke="#8CA9B8"
                stroke-width="0.66"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M79.7804 97.2364L92.904 89.6625C104.637 79.4973 112.623
                62.2032 113.156 44.3307C113.713 25.9689 98.2868 16.0373 82.4941
                25.2682C66.7014 34.4992 55.5575 56.4088 55.3462 74.7707C55.146
                91.7645 65.7004 101.463 79.7804 97.2364Z"
                stroke="#8CA9B8"
                stroke-width="0.66"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M60.5957 64.9727C60.1069 67.5099 59.8427 70.0852 59.8061
                72.6688C59.7316 75.7579 60.179 78.8373 61.1296 81.7775L78.3903
                92.2874C80.5238 91.8561 82.5889 91.1378 84.5295 90.1521C88.2203
                88.2604 91.5604 85.7515 94.4055 82.7339L60.5957 64.9727Z"
                fill="#6F8EA0"
            />
            <path
                d="M81.9493 96.0031L82.0049 91.2764C80.8475 91.717 79.6526
                92.052 78.4349 92.2773L78.1902 97.6713C78.724 97.5378 79.3023
                97.4044 79.8251 97.2376L81.9493 96.0031Z"
                fill="#6F8EA0"
            />
            <path
                d="M94.4054 82.7334C91.8079 85.4684 88.8075 87.79 85.5081
                89.6177V93.8995L92.8595 89.6622C93.8938 88.7613 94.9058 87.8049
                95.8845 86.7928L94.4054 82.7334Z"
                fill="#6F8EA0"
            />
            <path
                d="M60.5957 64.9727C60.1069 67.5099 59.8427 70.0852 59.8061
                72.6688C59.7316 75.7579 60.179 78.8373 61.1296 81.7775L78.3903
                92.2874C80.5238 91.8561 82.5889 91.1378 84.5295 90.1521C88.2203
                88.2604 91.5604 85.7515 94.4055 82.7339L60.5957 64.9727Z"
                stroke="#5A7D91"
                stroke-width="0.66"
                stroke-linejoin="round"
            />
            <path
                d="M81.9493 96.0031L82.0049 91.2764C80.8475 91.717 79.6526
                92.052 78.4349 92.2773L78.1902 97.6713C78.724 97.5378 79.3023
                97.4044 79.8251 97.2376L81.9493 96.0031Z"
                stroke="#5A7D91"
                stroke-width="0.66"
                stroke-linejoin="round"
            />
            <path
                d="M94.4054 82.7334C91.8079 85.4684 88.8075 87.79 85.5081
                89.6177V93.8995L92.8595 89.6622C93.8938 88.7613 94.9058 87.8049
                95.8845 86.7928L94.4054 82.7334Z"
                stroke="#5A7D91"
                stroke-width="0.66"
                stroke-linejoin="round"
            />
            <path
                d="M35.4496 1.36803C38.7224 1.3594 41.9787 1.83155 45.1143
                2.76936C48.2173 3.7147 88.9225 18.5955 99.3212 22.3991C107.629
                24.679 113.501 32.8534 113.157 44.3087C112.623 62.1812 104.637
                79.4753 92.904 89.6405L79.7805 97.2143C77.71 97.8623 75.5549
                98.1996 73.3855 98.2153C69.2785 98.2685 65.304 96.7622 62.2639
                94.0002H58.249C57.8318 94.0012 57.4193 94.0876 57.0368
                94.254C56.6543 94.4204 56.3098 94.6633 56.0247 94.9678C53.6891
                97.4812 47.4721 104.032 45.4813 105.911C42.1883 108.981 37.921
                110.796 33.4255 111.039C32.1077 111.058 30.8005 110.8 29.5885
                110.282C25.5069 108.481 12.5168 100.095 8.75768 92.1429C4.99857
                84.1909 3.96426 78.1518 6.62233 71.0117C9.2804 63.8716 10.7707
                60.1125 10.7707 60.1125C10.7707 60.1125 3.5194 52.3274 2.11807
                47.6563C-0.0172853 40.494 2.62966 28.0377 6.56673
                20.2303C10.0033 13.4461 18.8005 4.54882 24.973 2.84721C28.3822
                1.8822 31.9066 1.3846 35.4496 1.36803ZM30.7897 98.7602C34.1261
                98.7602 37.4626 96.7472 41.3774 93.0326C43.1573 91.0698 44.7059
                88.9092 45.9929 86.5931C46.2154 86.227 46.2842 85.7877 46.1842
                85.371C46.0842 84.9544 45.8236 84.5941 45.4591 84.3688L26.1853
                72.135C25.2352 71.5276 24.1058 71.2636 22.9849 71.387C21.864
                71.5105 20.8191 72.0138 20.0239 72.8134C19.7792 73.0581 19.4789
                73.4029 19.1787 73.7699C18.124 75.0743 17.3651 76.5922 16.9543
                78.2186C16.3783 80.2818 15.9618 82.3863 15.7087 84.5134C15.5664
                86.1054 15.8282 87.7075 16.4697 89.1714C17.1113 90.6353 18.1119
                91.9137 19.3789 92.888C21.7001 94.7552 24.1911 96.4009 26.8192
                97.8038C28.0589 98.44 29.4297 98.7789 30.823 98.7936L30.7897
                98.7602ZM35.4719 0.0334371C31.8247 0.0466122 28.196 0.551638
                24.6839 1.53486C17.9553 3.38105 8.95787 12.6565 5.39895
                19.6298C1.33955 27.5706 -1.4631 40.3939 0.816835 48.0789C2.07358
                52.2607 7.37861 58.4109 9.19143 60.4351C8.60199 61.9031 7.24514
                65.3508 5.30998 70.5335C2.27377 78.6968 3.9865 85.303 7.53431
                92.7768C11.438 101.029 24.5171 109.57 29.0547 111.583C30.4591
                112.187 31.9751 112.486 33.5033 112.462C38.3493 112.221 42.9542
                110.275 46.5045 106.968C48.5064 105.122 54.59 98.7046 57.1146
                96.0354C57.2695 95.8723 57.4557 95.7419 57.6621 95.6521C57.8684
                95.5623 58.0907 95.5149 58.3157 95.5127H61.8079C65.0553 98.2814
                69.1963 99.779 73.4634 99.7278C75.768 99.7106 78.0576 99.3546
                80.2587 98.6712L80.4144 98.6156L80.5479 98.5378L93.6714
                90.964L93.7937 90.8972L93.8938 90.8083C106.128 80.2093 114.091
                62.4481 114.625 44.4644C114.969 32.9201 109.164 23.7893 99.8328
                21.1868C87.7324 16.6937 48.5843 2.40235 45.5147 1.46813C42.2689
                0.487832 38.8959 -0.00687614 35.5052 7.21851e-05L35.4719
                0.0334371ZM30.7897 97.37C29.6275 97.3469 28.4861 97.0577 27.4532
                96.5248C24.9048 95.1669 22.4916 93.5693 20.2463 91.7536C19.163
                90.9227 18.3074 89.8314 17.7591 88.5811C17.2108 87.3308 16.9875
                85.9621 17.11 84.6024C17.3591 82.5549 17.7605 80.5288 18.3112
                78.5411C18.6897 77.1109 19.3726 75.7794 20.3131 74.6374C20.7023
                74.1592 20.9359 73.9034 21.0582 73.7699C21.7285 73.1014 22.6362
                72.7256 23.5828 72.7245C24.2597 72.7288 24.9221 72.9213 25.4958
                73.2806L44.7696 85.5144C44.8236 85.5487 44.8621 85.6029 44.8766
                85.6653C44.8912 85.7277 44.8807 85.7933 44.8474 85.848C43.6346
                88.0543 42.1677 90.1109 40.4766 91.976C36.584 95.6795 33.6146
                97.3255 30.8453 97.3255L30.7897 97.37Z"
                fill="#5A7D91"
            />
        </svg>
        <div class="is-gap is-xlarge" />

        <h4 class="is-size-5 has-text-centered">
            {message.translation}
            {#if notFound}
                <div class="is-gap is-large" />
                <button
                    class="button is-primary"
                    data-cy={$authenticated ? dataCyGoHome : dataCyGoRoot}
                    on:click={goto($authenticated ? '/home' : '/')}
                    class:is-loading={$checkingSignin}
                >
                    <span class="icon" class:is-hidden={$checkingSignin}>
                        <span class="arrow left has-text-white" />
                    </span>
                    <span
                        >{$authenticated
                            ? fix.translation
                            : i18n.t(
                                  'App.Errors.Request.Not_Found_Fix_Unauthenticated'
                              )}</span
                    >
                </button>
            {:else}
                <div class="is-gap" />
                {fix.translation}
            {/if}
        </h4>
    </div>
{/if}
