import { getFlag } from './launchdarkly';
import config from 'app.config';

// Stubbed function as reference for future Feature Flags
export const testFlag = () => {
    return config.featureflags.testFlag || getFlag('test-flag', false);
};

export const systemOutage = () => {
    return config.featureflags.systemOutage || getFlag('system-outage', false);
};
