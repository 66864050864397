<script>
    export let asset;
    export let assetActions = {};
    export let hideButtons = false;
    export let openDropdown = false;
    export let parentDataCy = '<parent>';

    import config from 'app.config';
    import DropDownComponent from '../elements/dropdown.svelte';
    import MoreIcon from '../../icons/more.svelte';
    import ShareIcon from '../../icons/share.svelte';
    import UploadIcon from '../../icons/upload.svelte';
    import DeleteIcon from '../../icons/delete.svelte';
    import RenameIcon from '../../icons/rename.svelte';
    import DownloadIcon from '../../icons/download.svelte';
    import CopyIcon from '../../icons/copy.svelte';
    import MoveIcon from '../../icons/move.svelte';
    import ShareFolderIcon from '../../icons/share.folder.svelte';
    import LeaveFolderIcon from '../../icons/leave.folder.svelte';
    import CloudIcon from '../../icons/cloud.svelte';
    import MoreInfoIcon from '../../icons/more.info.svelte';
    import AutoCAD from '../../icons/autocad.svelte';

    import AssetCopyComponent from './asset.copy.svelte';
    import AssetRenameComponent from './asset.rename.svelte';
    import AssetDeleteComponent from './asset.delete.svelte';
    import AssetDownloadComponent from './asset.download.svelte';
    import OpenOnDesktopComponent from './open.desktop.svelte';
    import FileShareComponent from './file.share.svelte';
    import FolderShareComponent from './folder.share.svelte';
    import SharedFolderLeaveComponent from './folder.share/folder.share.leave.svelte';
    import UploadComponent from '../upload/upload.svelte';
    import { gPermitted } from '../../providers/state/user';
    import { i18n } from '../../i18n';
    import { isDisplayingSharedFolders } from '../../providers/state/active.record';
    import { goToFile } from '../../providers/navigation';
    import { gActiveInfoPanel } from '../elements/components.state';
    import { isTouchDevice, isDesktop } from '../../providers/device';
    import { locateContextMenu } from '../utils.js';
    import { randomHex } from '../../providers/utils';
    import {
        actions,
        categories,
        trackEventWithDataType,
    } from '../../providers/analytics/ga';
    import { onDestroy } from 'svelte';

    const reCapFileExtensions = config.misc.reCapFileExtensions;

    const dataCyDropdown = `actions-dropdown-${randomHex()}`;
    const dataCyMoreActions = 'more-actions';
    const dataCyShareFileButton = 'share-file-button';
    const dataCyShareFolderButton = 'share-folder-button';
    const dataCyShareFile = 'share-file-item';
    const dataCyShareFolder = 'share-folder-item';
    const dataCyLeaveFolder = 'leave-folder-item';
    const dataCyUploadNewVersion = 'upload-new-version';
    const dataCyRename = 'rename-item';
    const dataCyCopy = 'copy-item';
    const dataCyMove = 'move-item';
    const dataCyDelete = 'delete-item';
    const dataCyDownload = 'download-item';
    const dataCyViewInBrowser = 'view-in-browser';
    const dataCyOpenInAutoCAD = 'open-in-autocad';
    const dataCyInfoPanel = 'more-info';

    let dropdownComponent, showModal, modal, props;

    const onModal = (_modal, _props, event) => {
        dropdownComponent.closeDropdown();
        modal = _modal;
        const onClose = () => {
            showModal = false;
            assetActions.delayRefresh(false);
        };
        props = { ..._props, onClose };
        showModal = true;
        assetActions.delayRefresh(true);
        event.stopPropagation();
    };

    const onCopy = (event) => {
        onModal(AssetCopyComponent, { assets: [asset] }, event);
    };

    const onMove = (event) => {
        if (isFolderShared) {
            return false;
        }
        onModal(AssetCopyComponent, { assets: [asset], move: true }, event);
    };

    const onRename = () => {
        onModal(AssetRenameComponent, { asset }, event);
    };

    const onDelete = (event) => {
        if (deleteRestricted) {
            return false;
        }
        onModal(AssetDeleteComponent, { assets: [asset] }, event);
    };

    const onDownload = (event) => {
        onModal(AssetDownloadComponent, { assets: [asset] }, event);
    };

    const onLeaveFolder = (event) => {
        onModal(
            SharedFolderLeaveComponent,
            {
                asset,
                refreshSharedWithMe: assetActions.refreshSharedWithMe,
            },
            event
        );
    };

    const onShareFile = (event) => {
        onModal(FileShareComponent, { asset }, event);
    };

    const onShareFolder = (event) => {
        onModal(FolderShareComponent, { asset }, event);
    };

    const onFileAdded = (data) => {
        // Call upload on the parent folder
        const assetParent = asset.parent;
        assetParent.uploads.upload(data);
    };

    const onViewInBrowser = () => {
        dropdownComponent.closeDropdown();
        goToFile(asset, `${parentDataCy}: ${dataCyViewInBrowser}`);
    };

    const openInfoPanel = () => {
        dropdownComponent.closeDropdown();
        $gActiveInfoPanel = true;
    };

    const onOpenInAutoCADWeb = () => {
        const autoCADWebUrl = `${config.apps.autoCADUrl}/drawings/${asset.id}/editor`;
        window.open(autoCADWebUrl, '_blank');

        // Log GA event to get insight on OpenInAutoCAD action usage
        trackEventWithDataType({
            category: categories.fileManagement,
            action: actions.openInAutoCAD,
            label: asset.fileType,
            value: asset.itemInfo.size,
            detail: `${parentDataCy}: ${dataCyOpenInAutoCAD}`,
        });
    };

    const onMoreActions = (event) => {
        if (openDropdown) {
            dropdownComponent.closeDropdown();
        } else {
            const dropdown = event.target.closest('.dropdown');
            const dropdownRect = dropdown.getBoundingClientRect();
            // Pass the bottom right as the default location
            locateContextMenu(
                {
                    x: dropdownRect.right,
                    y: dropdownRect.bottom,
                },
                dropdown,
                `${parentDataCy}: ${dataCyMoreActions}`
            );
            openDropdown = true;
        }
    };

    let observers = asset.observe({
        metadata: () => {
            isPrivateShared = asset.isPrivateShared;
            isFolderShared = asset.isFolderShared;
        },
        uploads: ($uploads) => {
            deleteRestricted = $uploads.length ? true : false;
        },
    });

    onDestroy(() => {
        if (observers) {
            observers.cancel();
        }
    });

    // These can change over time...
    let isPrivateShared = asset.isPrivateShared,
        isFolderShared = asset.isFolderShared,
        deleteRestricted = asset.uploads.hasActiveUploads();

    const isFile = asset.isFile,
        isFolder = !asset.isFile,
        // Methods
        canEdit = asset.canEdit,
        canDownload = asset.canDownload,
        canShare = asset.canShare,
        canReshare = asset.canReshare,
        // Computed
        isAssetOwner = asset.isAssetOwner,
        isReCapFile = reCapFileExtensions.includes(asset.fileType),
        isAutoCADFile = asset.fileType === 'dwg';

    let showViewInBrowser = isFile,
        showDownload =
            canDownload &&
            !$isDisplayingSharedFolders &&
            !(isFolder && isPrivateShared),
        showLeaveFolder = $isDisplayingSharedFolders,
        showInfoPanel = !$gActiveInfoPanel && !$isTouchDevice,
        showShareFile,
        isPrivateFolderShareAllowed,
        showShareFileButton,
        showShareFolder,
        showShareFolderButton,
        showUploadNewVersion,
        showMove,
        showCopy,
        showRename,
        showDelete;

    const evaluatePermissions = (_gPermitted) => {
        showShareFile =
            _gPermitted &&
            isFile &&
            canEdit &&
            isAssetOwner &&
            !isPrivateShared &&
            !isReCapFile;
        showShareFileButton = showShareFile && !hideButtons && $isDesktop;
        // Either Owner or Editor can share the folder
        isPrivateFolderShareAllowed =
            _gPermitted &&
            ((isFolder && canEdit && canShare) ||
                (isPrivateShared && canReshare)); // Editor
        showShareFolder = isFolder && isPrivateFolderShareAllowed;
        showShareFolderButton = showShareFolder && !hideButtons && $isDesktop;
        showUploadNewVersion = _gPermitted && isFile && canEdit;
        showMove = canEdit && _gPermitted && !isPrivateShared;
        showCopy = canEdit && _gPermitted;
        showRename = canEdit && _gPermitted;
        showDelete = canEdit && _gPermitted && !$isDisplayingSharedFolders;
    };

    // Need reactivity for write operations
    $: evaluatePermissions($gPermitted);
</script>

<div
    class="actions-container is-flex is-vertical-center"
    data-cy="asset-actions-container"
>
    {#if showShareFileButton}
        <button
            class="ga-{dataCyShareFileButton} button is-outlined share-button
            is-small is-hidden-mobile"
            data-cy={dataCyShareFileButton}
            on:click={onShareFile}
        >
            <span class="icon">
                <ShareIcon />
            </span>
            <span>{i18n.t('App.Folder_Content_List.Get_Link_Action')}</span>
        </button>
    {/if}
    {#if showShareFolderButton}
        <button
            class="ga-{dataCyShareFolderButton} button is-outlined share-button
            is-small is-hidden-mobile"
            data-cy={dataCyShareFolderButton}
            on:click={onShareFolder}
        >
            <span class="icon">
                <ShareFolderIcon fill="#3C3C3C" />
            </span>
            <span>{i18n.t('App.Folder_Content_List.Share_Folder_Action')}</span>
        </button>
    {/if}

    <!-- Show the Modal requested -->
    {#if showModal}
        <svelte:component this={modal} {...props} />
    {/if}

    <DropDownComponent
        position="right"
        bind:openDropdown
        bind:this={dropdownComponent}
        {dataCyDropdown}
    >
        <button
            slot="trigger"
            class="ga-{dataCyMoreActions} button is-outlined is-white is-small
            is-borderless"
            data-cy={dataCyMoreActions}
            on:click|stopPropagation={onMoreActions}
        >
            <span class="icon">
                <MoreIcon />
            </span>
        </button>
        <div slot="content" class="actions-content">
            {#if isAutoCADFile}
                <li
                    class="ga-{dataCyOpenInAutoCAD} dropdown-item is-vertical-center"
                    data-cy={dataCyOpenInAutoCAD}
                    on:click={onOpenInAutoCADWeb}
                >
                    <span class="icon">
                        <AutoCAD />
                    </span>
                    <span>
                        {i18n.t('App.Folder_Content_List.Open_In_Autocad')}
                    </span>
                </li>
            {/if}
            {#if showViewInBrowser}
                <li
                    class="ga-{dataCyViewInBrowser} dropdown-item is-vertical-center"
                    data-cy={dataCyViewInBrowser}
                    on:click={onViewInBrowser}
                >
                    <span class="icon">
                        <CloudIcon />
                    </span>
                    <span>
                        {i18n.t('App.Folder_Content_List.View_In_Browser')}
                    </span>
                </li>
                <OpenOnDesktopComponent assets={[asset]} />
                <hr class="dropdown-divider" />
            {/if}
            {#if showShareFile}
                <li
                    class="ga-{dataCyShareFile} dropdown-item is-vertical-center"
                    data-cy={dataCyShareFile}
                    on:click={onShareFile}
                >
                    <span class="icon">
                        <ShareIcon />
                    </span>
                    <span>
                        {i18n.t('App.Folder_Content_List.Get_Link_Action')}
                    </span>
                </li>
            {/if}
            {#if showUploadNewVersion}
                <UploadComponent {asset} type="file" onSelect={onFileAdded}>
                    <div
                        slot="trigger"
                        class="ga-{dataCyUploadNewVersion} dropdown-item is-vertical-center"
                        data-cy={dataCyUploadNewVersion}
                    >
                        <span class="icon">
                            <UploadIcon fill="#4d4d4d" />
                        </span>
                        <span>
                            {i18n.t(
                                'App.Folder_Content_List.Upload_Version_Action'
                            )}
                        </span>
                    </div>
                </UploadComponent>
            {/if}
            {#if showShareFolder}
                <li
                    class="ga-{dataCyShareFolder} dropdown-item is-vertical-center"
                    data-cy={dataCyShareFolder}
                    on:click={onShareFolder}
                >
                    <span class="icon">
                        <ShareFolderIcon fill="#4d4d4d" />
                    </span>
                    <span>
                        {i18n.t('App.Folder_Content_List.Share_Folder_Action')}
                    </span>
                </li>
            {/if}
            {#if showDownload}
                <li
                    class="ga-{dataCyDownload} dropdown-item is-vertical-center"
                    data-cy={dataCyDownload}
                    on:click={onDownload}
                >
                    <span class="icon">
                        <DownloadIcon />
                    </span>
                    <span
                        >{i18n.t(
                            'App.Folder_Content_List.Download_Action'
                        )}</span
                    >
                </li>
            {/if}
            {#if showMove}
                <li
                    class="ga-{dataCyMove} dropdown-item is-vertical-center has-tooltip-left
                        has-tooltip-arrow has-tooltip-hidden-mobile"
                    data-cy={dataCyMove}
                    class:is-disabled={isFolderShared}
                    data-tooltip={isFolderShared
                        ? i18n.t('App.Asset_Actions.Move_Not_Allowed_Tooltip')
                        : null}
                    on:click={onMove}
                >
                    <span class="icon">
                        <MoveIcon fill={isFolderShared && '#808080'} />
                    </span>
                    <span>{i18n.t('App.Folder_Content_List.Move_Action')}</span>
                </li>
            {/if}
            {#if showCopy}
                <li
                    class="ga-{dataCyCopy} dropdown-item is-vertical-center"
                    data-cy={dataCyCopy}
                    on:click={onCopy}
                >
                    <span class="icon">
                        <CopyIcon />
                    </span>
                    <span>{i18n.t('App.Folder_Content_List.Copy_Action')}</span>
                </li>
            {/if}
            {#if showRename}
                <li
                    class="ga-{dataCyRename} dropdown-item is-vertical-center"
                    data-cy={dataCyRename}
                    on:click={onRename}
                >
                    <span class="icon">
                        <RenameIcon />
                    </span>
                    <span>
                        {i18n.t('App.Folder_Content_List.Rename_Action')}
                    </span>
                </li>
            {/if}
            {#if showDelete}
                <hr class="dropdown-divider" />
                <li
                    class="ga-{dataCyDelete} dropdown-item is-light is-vertical-center has-tooltip-left
                    has-tooltip-arrow has-tooltip-hidden-mobile"
                    data-cy={dataCyDelete}
                    class:is-disabled={deleteRestricted}
                    data-tooltip={deleteRestricted
                        ? i18n.t('App.Asset_Actions.Delete_Not_Allowed_Tooltip')
                        : null}
                    on:click={onDelete}
                >
                    <span class="icon">
                        <DeleteIcon
                            fill={deleteRestricted ? '#808080' : '#4d4d4d'}
                        />
                    </span>
                    <span>
                        {i18n.t('App.Folder_Content_List.Delete_Action')}
                    </span>
                </li>
            {/if}

            {#if showLeaveFolder}
                <hr class="dropdown-divider" />
                <li
                    class="ga-{dataCyLeaveFolder} dropdown-item is-vertical-center"
                    data-cy={dataCyLeaveFolder}
                    on:click={onLeaveFolder}
                >
                    <span class="icon">
                        <LeaveFolderIcon />
                    </span>
                    <span
                        >{i18n.t('App.Folder_Sharing.Leave_Folder_Title')}</span
                    >
                </li>
            {/if}
            {#if showInfoPanel}
                <hr class="dropdown-divider" />
                <li
                    class="ga-{dataCyInfoPanel} dropdown-item is-vertical-center"
                    data-cy={dataCyInfoPanel}
                    on:click={openInfoPanel}
                >
                    <span class="icon">
                        <MoreInfoIcon />
                    </span>
                    <span>
                        {i18n.t('App.Folder_Content_List.More_Info')}
                    </span>
                </li>
            {/if}
        </div>
    </DropDownComponent>
</div>

<style>
    .share-button {
        margin-right: 10px;
    }
    .actions-content {
        min-width: 200px;
        max-width: 250px;
    }
</style>
