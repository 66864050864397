<script>
    export let viewable = null;
    export let file = null;
    export let collectionId = null;

    import config from 'app.config';
    import { URLSafeBase64 } from '../../providers/utils';

    const versionUrn = viewable.id;
    const vid = URLSafeBase64.encode(versionUrn);
    let src;

    // ShareFactory
    if (file.type === 'share') {
        src = `${config.apps.viewer}/drive/hub/${collectionId}/share/${file.id}/version/${vid}?source=drive`;
    } else {
        src = `${config.apps.viewer}/drive/hub/${collectionId}/urn/${file.id}/version/${vid}?source=drive`;
    }
</script>

<div class="viewer section has-centered-content">
    <iframe
        id="lmv-ifr"
        class="viewer-iframe has-all-space is-borderless"
        title={viewable.name}
        data-cy="lmv-viewer"
        {src}
    />
</div>
