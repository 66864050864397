import { initialize } from 'launchdarkly-js-client-sdk';
import config from 'app.config';
import { identity } from '../rest.sdks/request';
import { profile } from './state/user';

const ldKey = config.keys.launchdarkly;
const anonymousUser = { anonymous: true };

let launchDarklyClient;

export const initializeLaunchDarkly = async () => {
    let options = { useReport: true };
    if (config.misc.anonymizeUsers) {
        options.allAttributesPrivate = true;
    }
    const ldClient = initialize(ldKey, anonymousUser, options);

    await ldClient.waitForInitialization();

    launchDarklyClient = ldClient;

    return getFlag('maintenance-mode', false);
};

export const identifyLaunchDarkly = async (userProfile) => {
    if (launchDarklyClient) {
        // Identity_Get_AnalyticsId
        const path = `users/${userProfile.userId}/analytics`;
        const response = await identity(path);

        const analyticsId = response.analyticsId;
        const currentUser = launchDarklyClient.getContext();
        if (currentUser.key !== analyticsId) {
            // No need to check anonymizeUsers, allAttributesPrivate will filter
            const user = {
                key: analyticsId,
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
                email: userProfile.emailId,
            };
            const featureFlags = await launchDarklyClient.identify(user);
            // Update profile store to reflect correct FFs on UI - user.content.svelte
            profile.update((_profile) => {
                _profile.featureFlags = featureFlags; // Not used
                return _profile;
            });
        }
    }
};

export const getFlag = (flagName, defaultValue) => {
    return launchDarklyClient
        ? launchDarklyClient.variation(flagName, defaultValue)
        : defaultValue;
};

export const getAllFlags = () => {
    return launchDarklyClient ? launchDarklyClient.allFlags() : {};
};
