import memoizer from './memoizer';
import { drive, drive2 } from './request';
import { getPropValue, getExtension } from './utils';
import { getViewable } from './viewable.utils';
import {
    trackEvent,
    trackEventWithDataType,
    categories,
    actions,
    labels,
} from '../providers/analytics/ga';

const ShareFactory = memoizer.instancesGroup.memoize(
    (shareId, collectionId) => {
        const twoLegged = true;
        const methods = memoizer.newGroup(shareId);
        let publicSharePassword;
        const api = {
            id: shareId,
            type: 'share',
            info: methods.memoize(async () => {
                const url = `collections/shares/public/shares/${shareId}`;
                const params = `originCollectionId=${collectionId}`;
                // Drive_Get_Public_Share_By_Id
                const response = await drive(
                    `${url}?${params}`,
                    {},
                    'json',
                    null,
                    twoLegged
                );
                return response.data;
            }),
            viewable: methods.memoize(
                async (password) => {
                    trackEventWithDataType({
                        category: categories.publicFileShare,
                        action: actions.viewing,
                        label: labels.start,
                    });

                    // Store password entered by user, we need to pass while downloading a public share
                    publicSharePassword = password;
                    const options = publicSharePassword
                        ? { headers: { 'x-ads-password': publicSharePassword } }
                        : {};

                    const url = `collections/${collectionId}/items`;
                    const params = `itemIds=${shareId}`;
                    // Drive_Get_Items
                    const response = await drive(
                        `${url}?${params}`,
                        options,
                        'json',
                        null,
                        twoLegged
                    );
                    const metadata = response.data[0].attributes;
                    const fileId = response.data[0].id;
                    const viewableInfo = await getViewable(
                        shareId,
                        collectionId,
                        metadata,
                        true,
                        fileId,
                        options
                    );

                    trackEventWithDataType({
                        category: categories.publicFileShare,
                        action: actions.viewing,
                        label: labels.end,
                    });

                    return viewableInfo;
                },
                {
                    maxAge: 3000, // We want to keep polling for latest status
                }
            ),
            downloadPublicShare: async (viewableInfo) => {
                const ext = getExtension(viewableInfo.name);
                try {
                    // For public share download, we don't make an explicit call to get the downloadUrl
                    // Logging both fileDownloadStart and fileDownloadSuccess events to match the count in GA.
                    trackEvent({
                        category: categories.publicFileShare,
                        action: actions.fileDownloadStart,
                        label: ext,
                        value: viewableInfo.size,
                    });

                    // AWS doesn't allow modifying S3 pre-signed URL, so we've to call Drive2_Get_Download_File_Url again
                    // when user requests file download
                    const url = `collections/${collectionId}/files/${shareId}/download:signed`;
                    const params = 'useCdn=true&minutesExpiration=1';

                    // Drive2_Get_Download_File_Url:
                    const options = publicSharePassword
                        ? {
                              headers: {
                                  'x-ads-password': publicSharePassword,
                              },
                          }
                        : {};
                    const response = await drive2(
                        `${url}?${params}`,
                        options,
                        'json',
                        null,
                        twoLegged
                    );
                    const downloadUrl = getPropValue(
                        response,
                        'data.attributes.downloadUrl'
                    );

                    window.location.href = downloadUrl;

                    trackEvent({
                        category: categories.publicFileShare,
                        action: actions.fileDownloadSuccess,
                        label: ext,
                        value: viewableInfo.size,
                    });
                    return true;
                } catch (error) {
                    trackEvent({
                        category: categories.publicFileShare,
                        action: actions.fileDownloadFail,
                        label: ext,
                        value: viewableInfo.size,
                    });
                    throw error;
                }
            },
        };
        return api;
    }
);

export default ShareFactory;
