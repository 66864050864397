import config from 'app.config';
import { tipFromFileInfo } from '../providers/utils';
import { drive2 } from './request';
import { getExtension, getPropValue, URLSafeBase64 } from './utils';

const viewerTypes = {
    audio: ['audio'],
    image: ['image'],
    lmv: ['application/max', 'autodesk', 'application/x-3ds'],
    pdf: ['pdf'],
    recap: ['application/vnd.autodesk.recap'],
    text: [
        'text', // text/plain | text/css | text/*
        'txt', // application/txt
        'x-sh', // application/x-sh
        'html', // application/xhtml
        'xml', // application/xhtml+xml
        /** For below file types, extension will be matched,
         * as backend returns generic mimeType i.e. application/others */
        'css',
        'ics',
        'js',
        'json',
        'md',
        'rtf',
    ],
    video: ['video'],
};

const getViewableType = (metadata) => {
    // Refer:
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    // https://git.autodesk.com/A360/platform-viewing/blob/master/conf/resources/format-mime-types.properties
    const mimeType = metadata.mimeType;
    const fileType = getExtension(metadata.name);
    let viewableType = 'unknown'; // Start with unknown

    for (const [viewerType, supportedFiles] of Object.entries(viewerTypes)) {
        if (
            // Either mimetype or fileType should match
            (supportedFiles.some((type) => mimeType.includes(type)) ||
                supportedFiles.includes(fileType)) &&
            !config.misc.unviewableFileTypes.includes(fileType) // And not part of unviewableFileTypes
        ) {
            viewableType = viewerType;
            if (viewerType === 'text') {
                metadata.mimeType = 'text/plain';
            }
        }
    }

    // console.log(`Asset Mimetype: ${asset.mimeType}`);
    // console.log(`viewableType: ${viewableType}`);

    return viewableType;
};

export const getViewable = async (
    id,
    collectionId,
    metadata,
    /** Below params needed only for public share */
    isPublicShare = false,
    fileId = null,
    options = {}
) => {
    let viewableInfo = {};

    // For a public share, we've two identifiers - share_id and file_urn
    // use file_urn when assigning viewable id
    const idDecoded = URLSafeBase64.decode(isPublicShare ? fileId : id);
    viewableInfo.id = tipFromFileInfo(idDecoded, metadata.versionNumber); // urn:adsk.wipstg:fs.file:vf.6Npn7uNaSEWKJq6v4aWWaA?version=1

    viewableInfo.name = metadata.name;
    viewableInfo.versionNumber = metadata.versionNumber;
    viewableInfo.viewableType = getViewableType(metadata);

    // Drive2_Get_Download_File_Url:
    // explicitly specifying Content-Disposition: inline and Content-Type
    // in order to ensure viewing assets inside browser instead of download
    // Example: https://s3.amazonaws.com/com.autodesk.oss-ops-persistent/*/wip.dm?response-content-disposition=inline;
    const url = `collections/${collectionId}/files/${id}/download:signed`;
    const params = `inline=true&contentType=${metadata.mimeType}&useCdn=true&minutesExpiration=1`;
    const response = await drive2(
        `${url}?${params}`,
        options,
        'json',
        null,
        isPublicShare // 2LO
    );

    viewableInfo.viewingUrl = getPropValue(
        response,
        'data.attributes.downloadUrl'
    );
    return viewableInfo;
};
