import { URLSafeBase64, getPropValue, delay } from '../rest.sdks';
import { filesize } from 'filesize';
import TimeAgo from 'javascript-time-ago';
import { i18n, currentLanguage } from '../i18n';

import cs from 'javascript-time-ago/locale/cs';
import de from 'javascript-time-ago/locale/de';
import en from 'javascript-time-ago/locale/en';
import es from 'javascript-time-ago/locale/es';
import fr from 'javascript-time-ago/locale/fr';
import it from 'javascript-time-ago/locale/it';
import ja from 'javascript-time-ago/locale/ja';
import ko from 'javascript-time-ago/locale/ko';
import pl from 'javascript-time-ago/locale/pl';
import pt from 'javascript-time-ago/locale/pt';
import ru from 'javascript-time-ago/locale/ru';
import tr from 'javascript-time-ago/locale/tr';
import zhHant from 'javascript-time-ago/locale/zh-Hant';
import zh from 'javascript-time-ago/locale/zh';

[cs, de, en, es, fr, it, ja, ko, pl, pt, ru, tr, zhHant, zh].forEach(
    TimeAgo.addLocale
);

const locale = currentLanguage().processed;

const getLocaleLib = (locale) => {
    switch (locale) {
        case 'pseudo':
            // javascript-time-ago does not have the equivalent of a
            // pseudo-translation so we use Russian here just to show
            // that the strings have been addressed in globalization
            return 'ru';
        case 'zh_cn':
            return 'zh';
        case 'zh_tw':
            return 'zhHant';
        default:
            return locale;
    }
};

const _timeAgo = new TimeAgo(getLocaleLib(locale) || 'en-US');
export const timeAgo = (date) =>
    _timeAgo.format(typeof date === 'string' ? Date.parse(date) : date);

export { URLSafeBase64 };

export { getPropValue };

export { delay };

export const readableFileSize = (size) => {
    const options = {
        locale: locale,
    };
    const filesizeOrig = filesize(size, options);
    let filesizeParts;
    try {
        filesizeParts = filesizeOrig.split(' ').filter((n) => n);
    } catch {
        return filesizeOrig;
    }
    const number = filesizeParts[0];
    const units = filesizeParts[1];
    switch (units) {
        case 'B':
            return i18n.t('App.Size_Info.File_Size_Bytes', {
                NUMBER: number,
            });
        case 'KB':
            return i18n.t('App.Size_Info.File_Size_Kilobytes', {
                NUMBER: number,
            });
        case 'MB':
            return i18n.t('App.Size_Info.File_Size_Megabytes', {
                NUMBER: number,
            });
        case 'GB':
            return i18n.t('App.Size_Info.File_Size_Gigabytes', {
                NUMBER: number,
            });
        case 'PB':
            return i18n.t('App.Size_Info.File_Size_Petabytes', {
                NUMBER: number,
            });
        default:
            return filesizeOrig;
    }
};

export const split = (path, separator = '.', escape = '\\') => {
    if (!path) return [];
    if (Array.isArray(path)) return path;
    const keys = [];
    let key = '';
    for (let i = 0, l = path.length; i < l; ++i) {
        const charKey = path[i];
        if (charKey === separator && path[i - 1] !== escape) {
            if (key.length > 0) {
                keys.push(key);
            }
            key = '';
        } else {
            if (charKey !== escape) {
                key += path[i];
            }
        }
    }
    if (key.length > 0) {
        keys.push(key);
    }
    return keys;
};

// itemName validation
export const invalidItemName = (itemName, options = { padding: 0 }) => {
    // Initial state - explicitly not an error
    if (typeof itemName !== 'string') {
        return 0;
    }

    const regEx = /[/\n\r\t\f`?*\\<>|":]/g;
    const itemNameTrimmed = itemName.trim();

    if (itemNameTrimmed.length === 0) {
        return 'Empty';
    } else if (itemName.length + options.padding > 255) {
        return 'TooLong';
    } else if (itemName.match(regEx)) {
        return 'InvalidCharacters';
    } else if (itemNameTrimmed.endsWith('.')) {
        return 'TrailingDot';
    } else {
        return 0;
    }
};

export const randomHex = () => {
    return Math.floor(Math.random() * 16777215).toString(16);
};

export const firstLetterLowerCase = (str) => {
    return str.slice(0, 1).toLowerCase() + str.slice(1);
};

export const arrayEquals = (array1, array2) =>
    array1.length === array2.length &&
    array1.every((val) => array2.includes(val));

export const learnMoreLink = (href) => {
    return `<p><a href="${href}" target="_blank">${i18n.t(
        'App.Upload_Actions.Learn_More'
    )}</a></p>`;
};

// UI roles to backend permissions mapping
export const getPermissionsFromRole = (role) => {
    // prettier-ignore
    const roleToPermissionsMapping = {
        view:     ['browse', 'view'],
        download: ['browse', 'view', 'read'],
        rootEdit: ['browse', 'view', 'read', 'write', 'delete'],
        edit:     ['browse', 'view', 'read', 'write', 'share', 'delete'],
    };
    return (
        roleToPermissionsMapping[role] ||
        i18n.t('App.Folder_Sharing.Unknown_Permission')
    );
};

// Used for shared folders tooltip in Shared With Me page
export const getRoleFromPermissions = (permissions) => {
    let tooltip;
    if (permissions.includes('write') && permissions.includes('share')) {
        tooltip = i18n.t('App.Folder_Sharing.Can_Edit');
    } else if (permissions.includes('read')) {
        tooltip = i18n.t('App.Folder_Sharing.Can_Download');
    } else if (permissions.includes('view')) {
        tooltip = i18n.t('App.Folder_Sharing.Can_View');
    } else {
        tooltip = i18n.t('App.Folder_Sharing.Unknown_Permission');
    }
    return tooltip;
};

export const hasPermission = (assetInfo, permission, type = 'effective') => {
    const permissions = getPropValue(assetInfo, `permissions.${type}`) || [];
    return permissions.includes(permission);
};

// When invalid JSON input is sometimes expected
export const safeJSONParse = (source) => {
    try {
        const json = JSON.parse(source);
        if (typeof json === 'object') {
            return json;
        }
    } catch (error) {
        // ignore invalid input
    }

    // return an empty object
    return {};
};

export const tipFromFileInfo = (idDecoded, versionNumber) => {
    const fileUrn = idDecoded.replace('dm.lineage:', 'fs.file:vf.');
    return `${fileUrn}?version=${versionNumber}`;
};

export const emptyImage =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADIAQAAAACFI5MzAAAAAnRSTlMAAHaTzTgAAAABb3JOVAHPoneaAAAAHElEQVRYw+3BgQAAAADDoPlTX+EAVQEAAAAAfAYUUAABAJ8jFQAAAABJRU5ErkJggg==';

export default {
    URLSafeBase64,
    getPropValue,
    timeAgo,
    readableFileSize,
    split,
    randomHex,
    firstLetterLowerCase,
    arrayEquals,
    learnMoreLink,
    getPermissionsFromRole,
    getRoleFromPermissions,
    hasPermission,
    safeJSONParse,
};
