<script>
    export let collectionId = null; // Legacy path component
    export let folderId = null;

    import BreadcrumbComponent from '../components/breadcrumbs.svelte';
    import DataLegend from '../components/data.legend.svelte';
    import FirstTimerModal from '../components/first.timer.svelte';
    import FolderContent from '../components/folder.content/folder.content.svelte';
    import EntitlementBanner from '../components/banners/entitlement.banner.svelte';
    import DesktopConnectorBanner from '../components/banners/desktop.connector.banner.svelte';
    import FolderShareModal from '../components/folder.content/folder.share/folder.share.intro.modal.svelte';
    import ListLoader from '../components/list.loader.svelte';
    import AccountSetup from '../components/account.setup.svelte';
    import NotificationsPanel from '../components/elements/notifications.panel.svelte';
    import SomethingWentWrong from '../components/something.wrong.svelte';
    import UploadInfo from '../components/upload/upload.info.svelte';
    import {
        currentFolder,
        SHARED_WITH_ME_PATH,
    } from '../providers/state/active.record';
    import { FolderFactory } from '../rest.sdks';
    import { droparea } from '../components/upload/files.drag.drop';
    import {
        gDriveInfo,
        gInitiationError,
        gEntitled,
        showOpenOnDesktopBanner,
        gTenantMigrationLock,
    } from '../providers/state/user';
    import { isMobile } from '../providers/device';
    import { goToShared } from '../providers/navigation';
    import { trackEvent, categories, actions } from '../providers/analytics/ga';

    const dataCy = 'home-container';

    let folder, lastError, dropAreaInstance, canEdit;

    const onFilesDrop = async (event) => {
        if (folder && canEdit) {
            // TODO: This can throw - add Integration tests...
            folder.uploads.upload(event.detail);
        }
    };

    const setupDropArea = () => {
        // If dropzone is already set up on body,
        // clean up existing listeners created by us - files.drag.drop.js#L38-L40.
        if (dropAreaInstance) {
            dropAreaInstance.destroy();
            dropAreaInstance = null;
        }
        // If folder has write permission setup dropzone on body
        if (canEdit) {
            dropAreaInstance = droparea(document.body);
        }
    };

    // Folder Ingestion Pattern
    const setupFolderAsset = async (id) => {
        // Establish metadata before initializing components
        try {
            // Create this folder if it does not exist
            const _folder = FolderFactory(id);
            await _folder.setup();
            // Now that the folder is initialized, trigger an update
            folder = _folder;
            currentFolder.set(folder);
            canEdit = folder.canEdit;
            setupDropArea();
        } catch (error) {
            lastError = error;
        }
    };

    $: {
        if ($gDriveInfo) {
            // Reset errors...
            lastError = null;

            if (collectionId === SHARED_WITH_ME_PATH) {
                // Log this redirection to keep track of this
                // Ideally these redirections should be reduced to zero.
                trackEvent({
                    category: categories.privateFolderShare,
                    action: actions.redirection,
                    label: `Redirecting from '/shared' to 'Shared with Me'`,
                });
                goToShared();
            } else {
                if (!folderId) {
                    folderId = $gDriveInfo.rootFolderId;
                }

                setupFolderAsset(folderId);
            }
        }
    }
</script>

<svelte:body on:files={onFilesDrop} />

{#if $gInitiationError || lastError}
    <SomethingWentWrong
        error={$gInitiationError || lastError}
        source={dataCy}
        assetType="folder"
    />
{:else}
    {#if !$gDriveInfo}
        <AccountSetup />
    {:else}
        <!-- Don't show Welcome/PFS/Un-entitled modal/banner while checking/creating Drive Hub -->
        {#if $gEntitled === true}
            <!-- For new users one-time Welcome modal -->
            <FirstTimerModal />

            <!-- For existing users one-time PFS modal -->
            <FolderShareModal />
        {:else if $gEntitled === false && !$gTenantMigrationLock}
            <!-- Banner for Un-entitled users -->
            <EntitlementBanner />
        {/if}

        {#if $showOpenOnDesktopBanner}
            <DesktopConnectorBanner />
        {/if}
    {/if}
    <div
        class="container is-fluid columns has-all-space is-marginless
            home-container"
        class:mobile={$isMobile}
        class:not-mobile={!$isMobile}
        data-cy={dataCy}
    >
        <div
            class="column is-narrow py-5 data-nav"
            class:not-mobile={!$isMobile}
        >
            <!-- Knows what to do based on $currentFolder -->
            <DataLegend />
        </div>

        <div class="column py-0 content-section">
            <section class="section">
                {#if folder}
                    <div class="section-head is-vertical-center">
                        <div
                            class="columns is-marginless has-all-space
                            is-desktop has-bottom-border"
                        >
                            <div class="column is-vertical-center">
                                <BreadcrumbComponent {folder} />
                            </div>
                        </div>
                    </div>
                    <!-- FolderContent is common component for MyData and SharedWithMe. -->
                    <div class="section-body">
                        <FolderContent {folder} />
                    </div>
                    <UploadInfo />
                {:else}
                    <div class="section-body">
                        <ListLoader />
                    </div>
                {/if}
            </section>
        </div>
        <NotificationsPanel />
    </div>
{/if}

<style>
    .data-nav.not-mobile {
        width: 210px;
    }

    .not-mobile .content-section {
        max-width: calc(100% - 210px);
    }

    .home-container.mobile {
        overflow: auto;
    }

    .section-head {
        min-height: 85px;
    }
</style>
