import { getPropValue } from './utils';
import { orderBy } from 'natural-orderby';

export const sortFolderContent = (folderContent, key, ascending) => {
    const getComparable = (data) => {
        const comparable = data.map((item) => [
            item.isNew ? `000000000001` : getPropValue(item, `itemInfo.${key}`),
            item,
        ]);
        return comparable;
    };

    // Uses Natural Sort. Much better than Server provided.

    let folders = getComparable(folderContent.folders);
    folders = orderBy(folders, (item) => item[0], ascending ? 'asc' : 'desc');
    folderContent.folders = folders.map((item) => item[1]);

    let files = getComparable(folderContent.files);
    files = orderBy(files, (item) => item[0], ascending ? 'asc' : 'desc');
    folderContent.files = files.map((item) => item[1]);

    return folderContent;
};

export const getUniqueSharersCount = (content) => {
    const uniqueSharers = new Set();
    if (content.data) {
        content.data.forEach((folder) => {
            const sharer = getPropValue(folder, 'attributes.createdBy.userId');
            uniqueSharers.add(sharer);
        });
    }
    return uniqueSharers.size;
};

export default {
    sortFolderContent,
    getUniqueSharersCount,
};
