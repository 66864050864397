<script>
    import Banner from '../elements/banner.svelte';
    import { i18n } from '../../i18n';

    const dataCy = 'wipnext-readonly-banner';
    const type = 'danger';
    const closable = false;
    let show = true;
</script>

<Banner {type} {show} {dataCy} {closable}>
    <div slot="title" class="is-vertical-center">
        {i18n.t('App.Misc.WIPNext_Read_Only_Banner_Msg')}
    </div>
</Banner>
