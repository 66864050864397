<script>
    export let assetActions = {};
    export let folder;

    import FolderIcon from '../../../icons/folder.svelte';
    import SharedFolderIcon from '../../../icons/shared.folder.svelte';
    import { timeAgo, getRoleFromPermissions } from '../../../providers/utils';
    import AssetActionsComponent from '../asset.actions.svelte';
    import { onDestroy } from 'svelte';
    import { droparea } from '../../upload/files.drag.drop';
    import Spinner from '../../spinner.svelte';
    import { i18n } from '../../../i18n';
    import { isDisplayingSharedFolders } from '../../../providers/state/active.record';
    import { gSelection } from '../../elements/components.state';
    import { locateContextMenu } from '../../utils.js';

    let metadata = folder.itemInfo;
    let isFolderShared = folder.isFolderShared;

    let observers = folder.observe({
        metadata: () => {
            metadata = folder.itemInfo;
            isFolderShared = folder.isFolderShared;
        },
    });

    const id = `${folder.idDecoded}-grid-item`;
    const dataCy = 'folder-grid-item';

    let containerElement;

    onDestroy(() => {
        if (observers) {
            observers.cancel();
        }
    });

    const dropArea = (node) => {
        return folder.canEdit ? droparea(node) : {};
    };

    const onFilesDrop = async (event) => {
        folder.uploads.upload(event.detail);
    };

    let showContextMenu = false;
    const onContextMenu = (event) => {
        $gSelection = [folder];
        locateContextMenu(event, containerElement, `${dataCy}: contextmenu`);
        showContextMenu = true;
    };
</script>

<div
    class="grid-container folder-grid-parent column
        is-vertical-center has-text-black-bis is-mobile is-one-fifth"
    data-cy={dataCy}
    bind:this={containerElement}
    on:files={onFilesDrop}
    on:dblclick={assetActions.gotoFolder(folder)}
    on:click={assetActions.gotoFolder(folder)}
    use:dropArea
    on:contextmenu|preventDefault={onContextMenu}
>
    {#if !metadata}
        <div class="has-centered-content has-all-space">
            <Spinner />
        </div>
    {:else}
        <div
            class="selectable-item folder-grid has-all-space columns
                    is-mobile is-vertical-center is-marginless can-hover
                    has-border box is-paddingless"
            {id}
            class:is-selected={$gSelection && $gSelection.includes(folder)}
        >
            <div class="column is-narrow is-vertical-center">
                <FolderIcon />
            </div>
            <div class="column is-paddingless is-clipped">
                <div
                    class="has-text-weight-semibold main-text has-ellipsis"
                    data-cy="item-name"
                >
                    {metadata.name}
                    {#if $isDisplayingSharedFolders}
                        <span
                            class="icon shared-folder-icon has-tooltip-right has-tooltip-arrow"
                            data-cy="{dataCy}-shared"
                            data-tooltip={i18n.t(
                                'App.Folder_Sharing.Shared_Icon_Tooltip_Sharee',
                                {
                                    role: getRoleFromPermissions(
                                        metadata.permissions.explicit
                                    ),
                                }
                            )}
                        >
                            <SharedFolderIcon />
                        </span>
                    {:else}
                        {#await isFolderShared then shared}
                            {#if shared}
                                <span
                                    class="icon shared-folder-icon"
                                    data-cy="{dataCy}-shared"
                                >
                                    <SharedFolderIcon />
                                </span>
                            {/if}
                        {/await}
                    {/if}
                </div>
                <div
                    class="is-size-7 has-ellipsis"
                    title={i18n.displayFullName(metadata.lastModifiedBy)}
                >
                    {i18n.t('App.Time_Messages.Modified_Time_Ago', {
                        TIME_AGO: timeAgo(metadata.modifiedDate),
                    })}
                </div>
            </div>
            <div class="column is-narrow" data-cy="list-action">
                <AssetActionsComponent
                    asset={folder}
                    {assetActions}
                    hideButtons={true}
                    parentDataCy={dataCy}
                    bind:openDropdown={showContextMenu}
                />
            </div>
        </div>
    {/if}
</div>

<style>
    .folder-grid-parent {
        min-height: 80px;
    }

    .folder-grid {
        height: 80px;
        border-radius: 5px;
    }
</style>
