<script>
    export let folder = null;
    export let assets = [];

    import BulkMoveIcon from '../../icons/bulk.move.svelte';
    import BulkCopyIcon from '../../icons/bulk.copy.svelte';
    import BulkDeleteIcon from '../../icons/bulk.delete.svelte';
    import BulkDownloadIcon from '../../icons/bulk.download.svelte';
    import AssetDeleteComponent from './asset.delete.svelte';
    import AssetDownloadComponent from './asset.download.svelte';
    import AssetCopyComponent from './asset.copy.svelte';
    import { gPermitted } from '../../providers/state/user';
    import { i18n } from '../../i18n';
    import { onMount } from 'svelte';

    const inactiveColor = '#666666';
    const primaryColor = '#0696D7';

    const dataCyBulkActions = 'bulk-actions-menu';
    const dataCyBulkDownload = 'bulk-download';
    const dataCyBulkMove = 'bulk-move';
    const dataCyBulkCopy = 'bulk-copy';
    const dataCyBulkDelete = 'bulk-delete';

    const showDownload = (event) => {
        enableDownload = true;
        event.stopPropagation();
    };
    const onDownloadDialogClose = () => {
        enableDownload = false;
    };

    const showMove = (event) => {
        enableMove = true;
        event.stopPropagation();
    };
    const onMoveDialogClose = () => {
        enableMove = false;
    };

    const showCopy = (event) => {
        enableCopy = true;
        event.stopPropagation();
    };
    const onCopyDialogClose = () => {
        enableCopy = false;
    };

    const showDelete = (event) => {
        enableDelete = true;
        event.stopPropagation();
    };
    const onDeleteDialogClose = () => {
        enableDelete = false;
    };

    let canEdit = false,
        canDownload = false,
        enableCopy = false,
        enableDelete = false,
        enableDownload = false,
        enableMove = false,
        isPrivateShared = false;

    onMount(() => {
        // This prevents detecting an initial selection
        // when double-clicking into folders
        assets = [];
    });

    $: {
        canEdit = assets.every((asset) => asset && asset.canEdit);
        canDownload = assets.every((asset) => asset && asset.canDownload);
        if (folder) {
            isPrivateShared = folder.isPrivateShared;
        }
    }
</script>

{#if assets.length > 0}
    <div class="actions-container is-flex is-vertical-center">
        <!-- This is an optimization to reduce DOM size & also number of Modal instances created. -->
        {#if enableDownload}
            <AssetDownloadComponent
                {folder}
                {assets}
                onClose={onDownloadDialogClose}
            />
        {:else if $gPermitted && enableMove}
            <AssetCopyComponent
                {assets}
                move={true}
                onClose={onMoveDialogClose}
            />
        {:else if $gPermitted && enableCopy}
            <AssetCopyComponent {assets} onClose={onCopyDialogClose} />
        {:else if enableDelete}
            <AssetDeleteComponent {assets} onClose={onDeleteDialogClose} />
        {/if}
    </div>

    <div class="bulk-actions level-item" data-cy={dataCyBulkActions}>
        <!-- Drive-467 - Disable bulk download on DMS till we can support it -->
        {#if canDownload && assets.length === 1 && (!isPrivateShared || assets[0].isFile === true)}
            <button
                class="ga-{dataCyBulkDownload} button is-white has-tooltip-bottom has-tooltip-arrow"
                data-cy={dataCyBulkDownload}
                data-tooltip={i18n.t('App.Bulk_Actions.Download_Tooltip')}
                on:click={showDownload}
            >
                <span class="icon is-small">
                    <BulkDownloadIcon
                        fill={enableDownload ? primaryColor : inactiveColor}
                    />
                </span>
            </button>
        {/if}
        {#if canEdit}
            {#if $gPermitted}
                {#if !isPrivateShared}
                    <button
                        class="ga-{dataCyBulkMove} button is-white has-tooltip-bottom has-tooltip-arrow"
                        data-cy={dataCyBulkMove}
                        data-tooltip={i18n.t('App.Bulk_Actions.Move_Tooltip')}
                        on:click={showMove}
                    >
                        <span class="icon is-small">
                            <BulkMoveIcon
                                fill={enableMove ? primaryColor : inactiveColor}
                            />
                        </span>
                    </button>
                {/if}
                <button
                    class="ga-{dataCyBulkCopy} button is-white has-tooltip-bottom has-tooltip-arrow"
                    data-cy={dataCyBulkCopy}
                    data-tooltip={i18n.t('App.Bulk_Actions.Copy_Tooltip')}
                    on:click={showCopy}
                >
                    <span class="icon is-small">
                        <BulkCopyIcon
                            fill={enableCopy ? primaryColor : inactiveColor}
                        />
                    </span>
                </button>
                <button
                    class="ga-{dataCyBulkDelete} button is-white has-tooltip-bottom has-tooltip-arrow"
                    data-cy={dataCyBulkDelete}
                    data-tooltip={i18n.t('App.Bulk_Actions.Delete_Tooltip')}
                    on:click={showDelete}
                >
                    <span class="icon is-small">
                        <BulkDeleteIcon
                            fill={enableDelete ? primaryColor : inactiveColor}
                        />
                    </span>
                </button>
            {/if}
            <span class="bulk-text is-size-7">
                {i18n.t('App.Bulk_Actions.Items_Selected', {
                    count: Object.entries(assets).length,
                })}
            </span>
        {/if}
    </div>
{/if}

<style>
    .bulk-actions {
        margin-left: 20px;
    }
    button {
        margin-right: 8px;
    }
    .bulk-text {
        margin-left: 8px;
    }
</style>
