<script>
    import UserAvatar from './user.avatar.svelte';
    import DesktopConnectorDownload from './desktop.connector.download.svelte';
    import SideNavComponent from './elements/sidenav.svelte';
    import { profile, signOut } from '../providers/state/user';
    import config from 'app.config';
    import { prefixUrl } from '../providers/navigation';
    import { i18n, currentLanguage } from '../i18n';
    import { testFlag } from '../providers/feature.flag';

    const year = new Date().getUTCFullYear();

    const links = config.links;
    const servers = config.servers;

    const id = 'user-content';
    const dataCy = 'user-content';
    const dataCyLogout = 'logout';
    const dataCyProfileSettings = 'profile-settings';
    const dataCyHelp = 'help-button';
    const dataCyThirdParty = 'third-party';
    const dataCyPrivacyPolicy = 'privacy-policy';
    const dataCyPrivacySettings = 'privacy-settings';
    const dataCyTermsOfUse = 'terms-of-use';
    const dataCyCompliance = 'ccpa-compliance';
    const currentLocale = currentLanguage().processed;

    const thirdparty = prefixUrl(config.release.thirdpartyLicensesLoc);

    let version = '';
    $: if ($profile) {
        const userState = [testFlag()]
            .map((value) => (value ? '1' : '0'))
            .join('');

        // Leave the following as it is, as rollup injects the git hash
        version = 'APP_VERSION' + `:${userState}`;
    }
</script>

{#if $profile}
    <SideNavComponent {id} {dataCy}>
        <div slot="trigger" data-cy="user">
            <UserAvatar userProfile={$profile} />
        </div>
        <img
            slot="title"
            class="drive-logo"
            src="DRIVE_ASSETS/images/autodesk-logo-drive.svg"
            alt="logo"
        />
        <div slot="content">
            <div class="container is-fluid">
                <div class="profile">
                    <div class="user-name is-size-6 has-text-weight-semibold">
                        {i18n.displayFullName($profile)}
                    </div>
                    <div class="user-email is-size-7 has-text-weight-medium">
                        {$profile.emailId}
                    </div>
                </div>
                <div class="buttons is-marginless">
                    <a
                        class="ga-{dataCyProfileSettings} button is-primary"
                        data-cy={dataCyProfileSettings}
                        href={servers.oxygen}
                        target="_blank"
                    >
                        {i18n.t('App.Primary_Buttons.Profile_Settings_Btn')}
                    </a>
                    <button
                        class="ga-{dataCyLogout} button is-outlined"
                        data-cy={dataCyLogout}
                        on:click={signOut}
                    >
                        {i18n.t('App.Primary_Buttons.Logout_Btn')}
                    </button>
                </div>
                <div class="is-gap" />
                <div class="is-size-7" data-cy="app-version">
                    {i18n.t('App.User_Content.Software_Version_String', {
                        VERSION: version,
                    })}
                </div>
            </div>
            <div class="is-gap is-large is-divider" />
            <div class="container is-fluid">
                <div class="desktop-connector-info is-relative">
                    <div
                        class="desktop-connector-title subtitle is-6
                        has-text-weight-semibold"
                    >
                        {i18n.t(
                            'App.User_Content.Desktop_Connector_Info_Title'
                        )}
                    </div>
                    <div
                        class="desktop-connector-message subtitle is-7
                        has-text-weight-medium"
                    >
                        {i18n.t('App.User_Content.Desktop_Connector_Info_Desc')}
                    </div>
                    <div
                        class="desktop-connector-notes subtitle is-7
                        has-text-weight-bold"
                    >
                        {i18n.t(
                            'App.User_Content.Desktop_Connector_Assemblies'
                        )}
                    </div>
                    <img
                        class="cloud-sync-img"
                        src="DRIVE_ASSETS/images/cloud-sync.png"
                        alt="cloud-sync"
                    />
                </div>
                {#if navigator.platform === 'Win32'}
                    <DesktopConnectorDownload
                        hasIcon={false}
                        isFullWidth={true}
                        isCenterAligned={true}
                    >
                        <span slot="title">
                            {i18n.t('App.Primary_Buttons.Download_Btn')}
                        </span>
                    </DesktopConnectorDownload>
                {:else}
                    <div
                        class="desktop-connector-message subtitle is-7
                        has-text-weight-medium"
                    >
                        <a href={links.desktopconnectorInstall} target="_blank">
                            {i18n.t('App.User_Content.Learn_More_Link')}
                        </a>
                    </div>
                {/if}
            </div>
            <div class="is-gap is-large is-divider" />
            <div class="container is-fluid">
                <a
                    href={links.help}
                    class="ga-{dataCyHelp} button is-outlined is-fullwidth"
                    target="_blank"
                    data-cy={dataCyHelp}
                >
                    {i18n.t('App.Primary_Buttons.Help_Btn')}
                </a>
            </div>
        </div>
        <div class="container is-fluid" slot="footer">
            <a
                class="ga-{dataCyThirdParty} is-block is-size-7"
                target="_blank"
                rel="noopener noreferrer"
                data-cy={dataCyThirdParty}
                href={thirdparty}
            >
                {i18n.t('App.User_Content.Dependencies_Link')}
            </a>
            <div class="is-gap" />
            <a
                class="ga-{dataCyPrivacyPolicy} is-block is-size-7"
                target="_blank"
                rel="noopener noreferrer"
                data-cy={dataCyPrivacyPolicy}
                href={links.privacy}
            >
                {i18n.t('App.User_Content.Privacy_Policy_Link')}
            </a>
            <div class="is-gap" />
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a
                class="ga-{dataCyPrivacySettings} is-block is-size-7"
                href="javascript:;"
                data-cy={dataCyPrivacySettings}
                data-opt-in-preferences=""
                data-multi-optout-preferences="en"
                data-wat-linkname="manage-privacy-settings-footer-link"
            >
                {i18n.t('App.User_Content.Privacy_Settings_Link')}
            </a>
            <div class="is-gap" />
            <a
                class="ga-{dataCyTermsOfUse} is-block is-size-7"
                target="_blank"
                rel="noopener noreferrer"
                data-cy={dataCyTermsOfUse}
                href={links.terms}
            >
                {i18n.t('App.User_Content.Terms_of_Use_Link')}
            </a>
            {#if currentLocale === 'en'}
                <div class="is-gap" />
                <a
                    class="ga-{dataCyCompliance} is-block is-size-7"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-cy={dataCyCompliance}
                    href={links.ccpaCompliance}
                >
                    {i18n.t('App.User_Content.CCPA_Compliance_Link')}
                </a>
            {/if}
            <div class="is-gap" />
            <img
                class="is-block nav-link-logo"
                src="DRIVE_ASSETS/images/autodesk-logo-primary-rgb-black.svg"
                alt="Autodesk"
            />
            <div class="is-gap" />
            <div class="copyright subtitle is-7">
                {i18n.t('App.User_Content.Software_Copyright', {
                    YEAR: year,
                    COMPANY: config.desc.company,
                })}
            </div>
        </div>
    </SideNavComponent>
{/if}

<style>
    .drive-logo {
        height: 15px;
        width: auto;
    }

    .profile {
        padding: 20px 0px;
    }

    .desktop-connector-info {
        padding-right: 80px;
        white-space: normal;
    }

    .cloud-sync-img {
        height: 100px;
        position: absolute;
        right: 0;
        top: 30px;
    }

    .nav-link-logo {
        height: auto;
        margin-top: 15px;
        width: 128px;
    }

    .copyright {
        padding-bottom: 5px;
    }
</style>
