<script>
    import Banner from '../elements/banner.svelte';
    import config from 'app.config';
    import { i18n } from '../../i18n';

    const learnMoreLink = config.links.subscriptionHelp;
    const dataCy = 'entitlement-expired-banner';
    const type = 'warning';
    let show = true;

    const options = {
        showBanner: () => {
            show = true;
        },
        hideBanner: () => {
            show = false;
        },
    };
</script>

<Banner {type} {options} {show} {dataCy}>
    <div slot="title" class="is-vertical-center is-size-7-mobile">
        {i18n.t('App.EntitlementCheck.Banner_Msg')}
        <a
            id="btn-learn-more"
            href={learnMoreLink}
            target="_blank"
            class="button is-hidden-mobile has-text-grey-dark is-link has-text-weight-semibold"
        >
            {i18n.t('App.EntitlementCheck.Learn_More_Btn')}
        </a>
    </div>
</Banner>
