<script>
    export let name = 'Spinner';
    export let size = 'is-medium';
    import { onMount } from 'svelte';
    import { trackWait } from '../providers/track.wait';

    let instance;

    onMount(() => {
        return trackWait({ name, instance }).lifespan;
    });
</script>

<button
    class="spinner button {size} is-white is-loading"
    bind:this={instance}
/>

<style>
    .spinner {
        background-color: transparent !important;
    }
</style>
