import {
    actions,
    categories,
    trackEventWithDataType,
} from '../providers/analytics/ga';

export const locateContextMenu = (event, containerElement, label) => {
    // Log GA event
    trackEventWithDataType({
        category: categories.infoDrive,
        action: actions.contextMenu,
        label,
    });

    const contextMenu =
        containerElement.getElementsByClassName('dropdown-menu')[0];

    // Find the Containing Block - .dropdown-menu is positioned relative to that
    //  https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block#identifying_the_containing_block
    const containingBlock = contextMenu.closest('.dropdown');
    const containingBlockRect = containingBlock.getBoundingClientRect();

    // Get our clipping element - we must fit within this
    const clipElement = containerElement.closest('div.section-body');
    const clipElementRect = clipElement.getBoundingClientRect();

    // At this point, this rect is 0x0:
    //  contextMenu.getBoundingClientRect();

    // Display ourselves so we can get our dimensions using inline styles
    // We should already have 'position: absolute'
    contextMenu.style.display = 'block'; // vs. none
    const contextMenuRect = contextMenu.getBoundingClientRect();

    // Now, adjust our location based on our rules...
    let top, left;
    // Vertical positioning:
    if (contextMenuRect.height > clipElementRect.height) {
        // console.log("Y: Doesn't fit at all - top align");
        top = clipElementRect.top;
    } else if (event.y + contextMenuRect.height > clipElementRect.bottom) {
        // console.log(
        //     "Y: Doesn't fit below event location. Bottom align, to avoid adding more vertical space"
        // );
        top = clipElementRect.bottom - contextMenuRect.height;
    } else {
        // console.log("Y: Fit's normally");
        top = event.y;
    }

    // Horizontal positioning
    if (contextMenuRect.width > clipElementRect.width) {
        // console.log("X: Doesn't fit at all - left align");
        left = clipElementRect.left;
    } else if (event.x + contextMenuRect.width > clipElementRect.right) {
        // console.log(
        //     "X: Doesn't fit right of event location. Right align at event location, to avoid adding more horizontal space"
        // );
        // or right border: clipElementRect.right
        left = event.x - contextMenuRect.width;
    } else {
        // console.log("X: Fit's normally");
        left = event.x;
    }

    // Apply the position
    contextMenu.style.top = top - containingBlockRect.top + 'px';
    contextMenu.style.left = left - containingBlockRect.left + 'px';

    // Adjust tooltip position
    const dropdownItemsWithTooltips =
        containerElement.getElementsByClassName('has-tooltip-arrow');
    const tooltipWidth = 350;
    for (let item of dropdownItemsWithTooltips) {
        if (left < clipElementRect.left + tooltipWidth) {
            item.classList.remove('has-tooltip-left');
            item.classList.add('has-tooltip-right');
        } else {
            item.classList.remove('has-tooltip-right');
            item.classList.add('has-tooltip-left');
        }
    }
};

export default {
    locateContextMenu,
};
