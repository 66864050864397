<script>
    export let asset = null;
    export let onClose = () => {};

    import copy from 'copy-to-clipboard';
    import FileIcon from '../../icons/file.svelte';
    import { i18n } from '../../i18n';
    import ModalComponent from '../elements/modal.svelte';
    import { notificationsStore } from '../../components/elements/notifications.store.js';
    import { onDestroy } from 'svelte';
    import { randomHex } from '../../providers/utils';
    import AssetModalHeader from './asset.modal.header.svelte';
    import SomethingWentWrong from '../something.wrong.svelte';
    import Spinner from '../spinner.svelte';
    import { getExtension, getPropValue } from '../../rest.sdks/utils';
    import config from 'app.config';

    const dataCy = 'share-file';
    const id = `share-file-${randomHex()}`;
    const linkId = `${id}-input`;
    const shareSwitchInputId = `${id}-switch`;
    const downloadSwitchInputId = `${id}-downloadSwitch`;
    const dataCyDownloadSwitch = `${dataCy}-download-switch`;
    const passwordSwitchInputId = `${id}-passwordSwitch`;
    const dataCyPasswordSwitch = `${dataCy}-password-switch`;
    const dataCyPasswordField = `${dataCy}-set-password-input`;
    const passwordDummyValue = '************';

    const name = asset.itemInfo.name;

    let downloadSwitchDisabled = true,
        linkControlsDisabled = true,
        passwordSwitchDisabled = true,
        passwordInputDisabled = false,
        passwordSaveDisabled = true,
        anyApiRequestInProgress = false,
        downloadSwitchInProgress = false,
        passwordSwitchInProgress = false,
        createLinkCopiedNotification = false,
        createPasswordUpdatedNotification = false,
        shareChangeInProgress = false,
        share,
        shareFetched = false,
        passwordProtectedShare = false,
        assetShareUrl = '',
        lastError,
        modalInstance,
        observers,
        isFileNotViewable = !asset.isViewableFileType,
        password = '',
        toggleStates = {
            share: false,
            download: false,
            password: false,
        };

    const refreshShareInfo = async () => {
        share = null;
        shareFetched = false;
        try {
            share = await asset.sharing.info();
            assetShareUrl = getPropValue(share, 'accessUrl') || '';
            toggleStates.share = Boolean(assetShareUrl);
            passwordProtectedShare = getPropValue(
                share,
                'attributes.passwordProtected'
            );
            toggleStates.password =
                assetShareUrl &&
                (toggleStates.password || passwordProtectedShare);
            if (passwordProtectedShare) {
                password = passwordDummyValue;
            }
            toggleStates.download =
                isFileNotViewable ||
                getPropValue(share, 'attributes.downloadEnabled');
        } catch (error) {
            lastError = error;
        } finally {
            shareFetched = true;
        }
    };

    refreshShareInfo();

    const options = {
        onCloseEnd: onClose,
        showInfoPanel: isFileNotViewable,
    };

    observers = asset.observe({
        share: refreshShareInfo,
    });

    onDestroy(() => {
        if (observers) {
            observers.cancel();
        }
        if (createPasswordUpdatedNotification) {
            notificationsStore.add({
                type: 'success',
                title: 'App.Sharing_Actions.Password_Save_Notification_Title',
                body: 'App.Sharing_Actions.Password_Save_Notification_Body',
            });
        }
        if (createLinkCopiedNotification) {
            notificationsStore.add({
                type: 'success',
                title: 'App.Sharing_Actions.Copy_Link_Btn',
                body: 'App.Sharing_Actions.Copy_Link_Notification_Success',
            });
        }
    });

    $: {
        anyApiRequestInProgress =
            downloadSwitchInProgress ||
            passwordSwitchInProgress ||
            shareChangeInProgress;
        downloadSwitchDisabled =
            anyApiRequestInProgress || !assetShareUrl || isFileNotViewable;
        linkControlsDisabled = anyApiRequestInProgress || !assetShareUrl;
    }
    $: {
        // separate these more-interdependent reactive statements from the above
        passwordSwitchDisabled = anyApiRequestInProgress || !assetShareUrl;
        passwordInputDisabled =
            !assetShareUrl ||
            anyApiRequestInProgress ||
            (!toggleStates.password && !passwordProtectedShare);
        passwordSaveDisabled =
            passwordInputDisabled ||
            password.length <= 0 ||
            password == passwordDummyValue;
    }

    const shareToggle = async (event) => {
        lastError = null;
        shareChangeInProgress = true;
        try {
            if (event.target.checked) {
                await asset.sharing.on();
            } else {
                await asset.sharing.off();
            }
        } catch (error) {
            // revert to previous state
            toggleStates.share = !event.target.checked;
            lastError = error;
        }
        shareChangeInProgress = false;
    };

    const downloadToggle = async (event) => {
        lastError = null;
        downloadSwitchInProgress = true;
        try {
            await asset.sharing.setDownload(event.target.checked);
        } catch (error) {
            lastError = error;
        }
        downloadSwitchInProgress = false;
    };

    const passwordToggle = (event) => {
        if (!event.target.checked) {
            password = '';
            // don't remove the password unless one is saved
            if (passwordProtectedShare) {
                setPassword();
            }
        }
    };

    const setPassword = async () => {
        lastError = null;
        passwordSwitchInProgress = true;
        try {
            await asset.sharing.setPassword(password);
            if (password) {
                password = passwordDummyValue;
                createPasswordUpdatedNotification = true;
            } else {
                createPasswordUpdatedNotification = false;
            }
        } catch (error) {
            lastError = error;
            createPasswordUpdatedNotification = false;
        }
        passwordSwitchInProgress = false;
    };

    let passwordFieldInstance;

    const resetPassword = (event) => {
        event.stopPropagation();
        password = '';
        passwordFieldInstance.focus();
    };

    const onEnter = (event) => {
        if (event.keyCode == 13) {
            setPassword();
        }
    };

    let shareLinkinputInstance;

    const copyToClipboard = () => {
        const isSuccess = copy(shareLinkinputInstance.value);
        if (isSuccess) {
            selectUrl();
            createLinkCopiedNotification = true;
        }
    };

    const selectUrl = () => {
        shareLinkinputInstance.focus();
        shareLinkinputInstance.select();
    };
</script>

<ModalComponent
    {id}
    {dataCy}
    bind:this={modalInstance}
    bodyModifier="pb-4 has-bottom-border"
    hideGap={true}
    loadingState={!shareFetched}
    {options}
>
    <div class="has-all-space is-marginless" slot="title">
        <AssetModalHeader itemName={name} {modalInstance} {dataCy}>
            <FileIcon type={getExtension(name)} slot="icon" />
        </AssetModalHeader>
    </div>
    <div slot="content">
        {#if shareFetched}
            <div class="field">
                <div class="columns is-mobile pb-4">
                    <div class="column is-four-fifths is-vertical-center">
                        <div class="is-6" data-cy="share-label">
                            {i18n.t(
                                'App.Sharing_Actions.Public_Share_Status_Msg',
                                {
                                    SHARING_STATUS: assetShareUrl
                                        ? 'on'
                                        : 'off',
                                }
                            )}
                            <div class="has-text-grey-light subtitle is-7">
                                {#if assetShareUrl}
                                    {i18n.t(
                                        'App.Sharing_Actions.Stop_Sharing_Msg'
                                    )}
                                {:else}
                                    {i18n.t(
                                        'App.Sharing_Actions.Start_Sharing_Msg'
                                    )}
                                {/if}
                            </div>
                        </div>
                    </div>
                    <div
                        class="toggle-switch column is-one-fifth is-rightaligned is-vertical-center"
                    >
                        {#if shareChangeInProgress}
                            <div class="has-rightpadding">
                                <Spinner />
                            </div>
                        {:else}
                            <input
                                class="ga-{`${dataCy}-switch`} switch
                                    is-info is-rounded"
                                data-cy={`${dataCy}-switch`}
                                id={shareSwitchInputId}
                                disabled={anyApiRequestInProgress}
                                type="checkbox"
                                bind:checked={toggleStates.share}
                                on:change={shareToggle}
                            />
                            <label
                                data-cy={`${dataCy}-switch-label`}
                                for={shareSwitchInputId}>&nbsp;</label
                            >
                        {/if}
                    </div>
                </div>
                <div class="columns is-mobile">
                    <div class="column is-four-fifths is-vertical-center">
                        <div class="is-6" data-cy="download-label">
                            {i18n.t('App.Sharing_Actions.Download_Status_Msg')}
                        </div>
                    </div>
                    <div
                        class="toggle-switch column is-one-fifth
                        is-rightaligned is-vertical-center"
                    >
                        {#if downloadSwitchInProgress}
                            <div class="has-rightpadding">
                                <Spinner />
                            </div>
                        {:else}
                            <input
                                class="ga-{dataCyDownloadSwitch} switch
                                    is-info is-rounded"
                                data-cy={dataCyDownloadSwitch}
                                id={downloadSwitchInputId}
                                type="checkbox"
                                bind:checked={toggleStates.download}
                                disabled={downloadSwitchDisabled}
                                on:change={downloadToggle}
                            />
                            <label
                                data-cy={`${dataCyDownloadSwitch}-label`}
                                for={downloadSwitchInputId}
                            >
                                &nbsp;
                            </label>
                        {/if}
                    </div>
                </div>
            </div>
        {/if}
    </div>
    <div slot="content2">
        {#if shareFetched}
            <div class="field">
                <div class="columns is-mobile pb-4">
                    <div class="column is-four-fifths is-vertical-center">
                        <span
                            class="is-6 mr-3 password-toggle"
                            data-cy="password-label"
                            for={passwordSwitchInputId}
                        >
                            {i18n.t('App.Sharing_Actions.Set_Password_Msg')}
                        </span>
                        {#if passwordInputDisabled}
                            <span
                                class="is-6 password-reset has-text-weight-semibold has-text-grey-light"
                                data-cy="password-reset"
                            >
                                {i18n.t(
                                    'App.Sharing_Actions.Reset_Password_Msg'
                                )}
                            </span>
                        {:else}
                            <a
                                href="#{dataCyPasswordField}"
                                class="is-6 has-text-weight-semibold password-reset"
                                data-cy="password-reset"
                                on:click={resetPassword}
                            >
                                {i18n.t(
                                    'App.Sharing_Actions.Reset_Password_Msg'
                                )}
                            </a>
                        {/if}
                    </div>
                    <div
                        class="toggle-switch column is-one-fifth
                        is-rightaligned is-vertical-center"
                        data-cy={`${dataCy}-password-switch-container`}
                    >
                        {#if passwordSwitchInProgress}
                            <div class="has-rightpadding">
                                <Spinner />
                            </div>
                        {:else}
                            <input
                                class="ga-{dataCyPasswordSwitch} switch
                                is-info is-rounded"
                                data-cy={dataCyPasswordSwitch}
                                id={passwordSwitchInputId}
                                type="checkbox"
                                bind:checked={toggleStates.password}
                                disabled={passwordSwitchDisabled}
                                on:change={passwordToggle}
                            />
                            <label
                                data-cy={`${dataCyPasswordSwitch}-label`}
                                for={passwordSwitchInputId}
                            >
                                &nbsp;
                            </label>
                        {/if}
                    </div>
                </div>
                <div
                    class="has-text-grey-light is-7 mt-0 mb-2 password-message subtitle"
                >
                    {i18n.t('App.Sharing_Actions.Updating_Password_Msg')}
                </div>
                <div
                    class="columns pb-4 is-mobile is-marginless is-vertical-center control"
                >
                    <div class="column is-paddingless mr-4">
                        <input
                            class="input share-modal-input"
                            data-cy={dataCyPasswordField}
                            id={dataCyPasswordField}
                            type="password"
                            readonly={passwordInputDisabled}
                            disabled={passwordInputDisabled}
                            on:keydown={onEnter}
                            bind:value={password}
                            bind:this={passwordFieldInstance}
                        />
                    </div>
                    <div class="column is-narrow is-paddingless">
                        <button
                            class="ga-{`${dataCy}-set-password-button`}
                                button is-primary is-small
                                share-modal-button"
                            data-cy={`${dataCy}-set-password-button`}
                            disabled={passwordSaveDisabled}
                            on:click={setPassword}
                            >{i18n.t(
                                'App.Sharing_Actions.Save_Password_Btn'
                            )}</button
                        >
                    </div>
                </div>
                <div class="is-7 mt-0 mb-2 subtitle">
                    {i18n.t('App.Sharing_Actions.Everyone_Can_Access_Msg')}
                </div>
                <div class="columns is-mobile is-marginless is-vertical-center">
                    <div class="column is-paddingless mr-4">
                        <input
                            class="input share-modal-input"
                            type="text"
                            readonly
                            id={linkId}
                            data-cy="share-link-input"
                            value={assetShareUrl}
                            bind:this={shareLinkinputInstance}
                            on:click={selectUrl}
                            disabled={linkControlsDisabled}
                        />
                    </div>
                    <div class="column is-narrow is-paddingless">
                        <button
                            data-cy={`${dataCy}-copy-link`}
                            class="ga-{`${dataCy}-copy-link`} button
                                is-primary is-small share-modal-button"
                            on:click={copyToClipboard}
                            disabled={linkControlsDisabled}
                        >
                            {i18n.t('App.Sharing_Actions.Copy_Link_Btn')}
                        </button>
                    </div>
                </div>
            </div>
        {/if}
        {#if lastError}
            <SomethingWentWrong
                error={lastError}
                source={dataCy}
                inline={true}
            />
        {/if}
    </div>
    <div slot="footer-right-section">
        <button
            data-cy="{dataCy}-close"
            class="button is-primary share-modal-button is-small"
            on:click={modalInstance.close}
        >
            {i18n.t('App.Primary_Buttons.Close_Btn')}
        </button>
    </div>
    <div slot="trigger">
        <slot name="share-file-trigger" />
    </div>
    <div slot="info-content">
        <h4 class="is-size-6">
            {i18n.t(
                'App.Viewing_Actions.Public_File_Share.Not_Viewable_File_Type_Subject'
            )}
        </h4>
        <p class="is-size-7">
            {i18n.t(
                'App.Viewing_Actions.Public_File_Share.Not_Viewable_File_Type_Info'
            )}
            {@html i18n.t(
                'App.Viewing_Actions.Learn_More_Viewable_File_Type_Link',
                {
                    HTML_START:
                        '<a href="' +
                        config.links.learnMoreViewableFileTypes +
                        '" target="_blank">',
                    HTML_END: '</a>',
                }
            )}
        </p>
    </div>
</ModalComponent>

<style>
    .share-modal-button {
        height: 38px;
        width: 77px;
        margin-left: -3px;
    }
    .share-modal-button:disabled {
        background-color: #ffffff;
        color: #666666;
        border: 3px solid #666666;
    }
    .share-modal-input {
        font-size: 14px !important;
    }
    .columns {
        margin: 0 !important;
    }
    .column {
        padding: 0 !important;
    }
    .toggle-switch {
        height: 24px;
    }
    .switch[type='checkbox'] + label {
        margin-right: -0.75rem;
    }
</style>
