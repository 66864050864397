import { getExtension } from '../../rest.sdks/utils.js';
import { split } from '../../providers/utils';
import { notificationsStore } from '../../components/elements/notifications.store.js';
import config from 'app.config';
import {
    actions,
    categories,
    trackEvent,
} from '../../providers/analytics/ga.js';
import { fileName } from '../../rest.sdks/uploads/upload.utils.js';

const isInvalidName = (name) => {
    const regEx = /[/\n\r\t\f`?*\\<>|":]/g;
    const invalidFileName = name.match(regEx);
    return invalidFileName;
};

const setInvalidFolderErrorType = (file, folderName) => {
    file.errorType = uploadErrorType.INVALID_FOLDER_NAME;
    file.fileName = folderName;
    return file;
};

export const pathsToTree = (nodes) => {
    const tree = {
        name: 'root',
        children: {},
    };
    nodes.forEach((node) => {
        let path = split(node.name, '/').reverse();
        const parentId = path[1];
        const name = path[0];
        if (name === '.DS_Store') return;
        let loc = tree;
        for (let i = path.length - 1; i > 0; i--) {
            let key = path[i];
            if (!loc.children[key]) {
                loc.children[key] = {
                    name: key,
                    children: {},
                };
            }
            loc = loc.children[key];
        }
        loc.children[name] = {
            id: node.name,
            name,
            parentId,
            node,
        };
    });
    return tree;
};

export const groupFiles = (nodes, key = 'name') => {
    const tree = {};
    nodes.forEach((node) => {
        let path = split(node[key], '/');
        if (tree[path[0]]) {
            if (Array.isArray(tree[path[0]])) {
                tree[path[0]].push(node);
            } else {
                tree[path[0]] = [tree[path[0]], node];
            }
        } else {
            if (path.length > 1) {
                tree[path[0]] = [node];
            } else {
                tree[path[0]] = node;
            }
        }
    });
    return tree;
};

export const uploadErrorType = {
    FAILED: 'Failed',
    CANCELLED: 'Cancelled',
    INVALID_FILE_TYPE: 'Unsupported_Upload',
    INVALID_NAME: 'Invalid_Name',
    MAX_SIZE_EXCEEDED: 'Upload_Max_File_Size_Exceeded',
    SHOULD_BE_SILENT: 'Silent',
    INVALID_FOLDER_NAME: 'Invalid_Folder_Name',
};

export const checkFileForErrors = (file) => {
    const excluded = config.misc.unsupportedFileTypes;
    const isFolder = file.name.includes('/');

    // First, unsupported files should not display as a failure - case sensitive -
    if (config.misc.unsupportedFiles.includes(file.name.split('/').pop())) {
        file.errorType = uploadErrorType.SHOULD_BE_SILENT;
        return file;
    }
    const invalidFileType = excluded.includes(getExtension(file.name));
    if (invalidFileType) {
        file.errorType = uploadErrorType.INVALID_FILE_TYPE;
        return file;
    }
    if (isFolder) {
        let path = file.name.split('/');
        path.pop();
        let _folderName = '';
        // check if the folder contains nested folders
        if (path.length == 1) {
            _folderName = path[0];
            if (isInvalidName(_folderName)) {
                return setInvalidFolderErrorType(file, _folderName);
            }
        } else {
            for (let i = 0; i < path.length; i++) {
                _folderName = path[i];
                if (isInvalidName(_folderName)) {
                    return setInvalidFolderErrorType(file, _folderName);
                }
            }
        }
    }
    const _fileName = fileName(file);
    if (isInvalidName(_fileName)) {
        file.errorType = uploadErrorType.INVALID_NAME;
        return file;
    }
    if (isMaxFileSizeExceeded(file)) {
        file.errorType = uploadErrorType.MAX_SIZE_EXCEEDED;
    }

    return file;
};

export const MAX_FILE_SIZE = 6e9; // 6GB

export const isMaxFileSizeExceeded = (file) => {
    if (file.size > MAX_FILE_SIZE) {
        // Track FileSizeExceeded event
        trackEvent({
            category: categories.upload,
            action: actions.fileSizeExceeded,
            label: getExtension(file.name),
            value: file.size,
        });
        return true;
    } else {
        return false;
    }
};

let lastCancelledNotification = '';
export const addUploadCancelledNotification = (fileName) => {
    const notification = {
        type: 'danger',
        title: 'App.Upload_Actions.Cancelled_Notification_Title',
        body: `App.Upload_Actions.Cancelled_Single`,
        bodyParams: {
            FILE_NAME: fileName,
        },
    };

    if (lastCancelledNotification) {
        notificationsStore.close(lastCancelledNotification);
    }
    lastCancelledNotification = notificationsStore.add(notification);
};

let lastUploadFailedNotification = '';
export const addUploadFailedNotification = (
    totalCount,
    failCount,
    viewAllAction,
    errorType = 'Failed',
    fileName,
    invalidFolderCount
) => {
    let body;
    let bodyParams;
    if (errorType == 'Invalid_Folder_Name' && invalidFolderCount <= 1) {
        body = 'App.Upload_Actions.Invalid_Folder_Name';
        bodyParams = {
            FILE_NAME: fileName,
        };
        viewAllAction = null;
    } else if (failCount <= 1) {
        // include possibility of failCount === 0 to cover possible lag from uploadStore
        body = `App.Upload_Actions.${errorType}_Single`;
        bodyParams = {
            INVALID_COUNT: failCount,
        };
    } else {
        body = 'App.Upload_Actions.Failed_Multiple';
        bodyParams = {
            INVALID_COUNT: failCount,
            COUNT_TOTAL: totalCount,
        };
    }

    const notification = {
        type: 'danger',
        title: 'App.Upload_Actions.Failed_Notification_Title',
        body,
        bodyParams,
        viewAllAction,
    };

    if (lastUploadFailedNotification) {
        notificationsStore.close(lastUploadFailedNotification);
    }
    lastUploadFailedNotification = notificationsStore.add(notification);
    return lastUploadFailedNotification;
};

export const addUploadSuccessNotification = (uploadSuccessTotal, parent) => {
    notificationsStore.add({
        type: 'success',
        title: 'App.Upload_Actions.Uploading_Succeeded_Notification_Title',
        body:
            uploadSuccessTotal === 1
                ? 'App.Upload_Actions.Uploading_Succeeded_Single'
                : 'App.Upload_Actions.Uploading_Succeeded_Multiple',
        bodyParams: { ITEM_COUNT: uploadSuccessTotal, PARENT: parent },
        timeout: 8000,
    });
};

export default {
    pathsToTree,
    groupFiles,
};
