<script>
    import { currentLanguage } from '../i18n';
    const copyrightYear = new Date().getFullYear();
    const dataCyCompliance = 'ccpa-compliance';
    const currentLocale = currentLanguage().processed;
</script>

<footer class="footer" data-cy="footer">
    <div class="content">
        <p>
            &copy; <span id="copyright-year">{copyrightYear}</span> Autodesk
            Inc.
            <a
                href="http://www.autodesk.com/company/legal-notices-trademarks/privacy-statement"
                class=""
                target="_blank">Privacy</a
            >
            <!--<a class="c-nav__footer js-privacySettings ga-legal-privacy-settings" data-opt-in-preferences="true" href="javascript:;">Privacy Settings</a>-->
            <a
                href="http://www.autodesk.com/company/legal-notices-trademarks/terms-of-service-autodesk360-web-services"
                class=""
                target="_blank">Terms</a
            >
            {#if currentLocale === 'en'}
                <a
                    href="https://www.autodesk.com/company/legal-notices-trademarks/ccpa-do-not-sell"
                    class="ga-ccpa-compliance"
                    data-cy={dataCyCompliance}
                    target="_blank"
                    >Do Not Sell or Share My Personal Information</a
                >
            {/if}
        </p>
    </div>
</footer>

<style>
    footer.footer {
        font-size: 12px;
        padding: 1rem 1.5rem 1rem;
    }
    footer.footer a {
        color: #4a4a4a;
        text-decoration: underline;
        margin-left: 25px;
        white-space: nowrap;
    }
</style>
