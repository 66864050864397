<script>
    export let instance = null;
    export let options = null;
    export let show = false;
    export let closable = true;
    export let type = 'info';
    export let dataCy = '';

    import { onDestroy } from 'svelte';
    import CloseIcon from '../../icons/close.svelte';
    import InfoIcon from '../../icons/info.svelte';
    import WarningIcon from '../../icons/warning.svelte';
    import DangerIcon from '../../icons/danger.svelte';
    import { isMobile } from '../../providers/device';

    const fill = '#dbdbdb';

    instance = {
        show: () => {
            options.showBanner();
        },
        close: () => {
            options.hideBanner();
        },
    };

    onDestroy(() => {
        instance = null;
    });
</script>

<!--
    Banner component based upon Bulma hero component:
    https://bulma.io/documentation/layout/hero/
-->
{#if show}
    <section
        class="drive-banner hero is-{type} {show ? '' : 'is-hidden'}"
        data-cy={dataCy}
    >
        <div
            class="hero-body columns is-vcentered is-mobile is-marginless
            {$isMobile ? '' : 'is-paddingless'}"
        >
            <div
                class="column banner-icon banner-icon-{type} has-text-light has-centered-content is-paddingless
                   {$isMobile ? 'is-hidden' : ''}"
            >
                {#if type === 'warning'}
                    <WarningIcon {fill} />
                {:else if type === 'danger'}
                    <DangerIcon {fill} />
                {:else}
                    <InfoIcon {fill} />
                {/if}
            </div>
            <div
                class="column banner-body is-marginless has-text-grey-dark
                {$isMobile ? '' : 'is-paddingless is-desktop'}"
            >
                <slot name="title" />
                <slot name="subtitle" class="subtitle" />
                <slot name="content" class="content" />
            </div>
            {#if closable}
                <div
                    class="column is-narrow is-clickable
                    is-rightaligned"
                    on:click={instance.close}
                >
                    <slot name="close">
                        <span
                            class="icon banner-close-icon"
                            data-cy="{dataCy}-close"
                        >
                            <CloseIcon />
                        </span>
                    </slot>
                </div>
            {/if}
        </div>
    </section>
{/if}
