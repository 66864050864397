// Collect all pending Upload requests here
// Watch for the 'beforeunload' event only when uploads are pending
// and report them as abandoned
import { categories, actions, trackEventWithDataType } from '../analytics/ga';
import { writable } from 'svelte/store';

const uploads = new Map(); // Map full path to uploaded file to uploadObject

// Store uploads should persist until all added uploads have completed
export const uploadStore = (() => {
    const initState = () => ({ uploads: [], processed: 0, failed: 0 });
    const { subscribe, set, update } = writable(initState());

    function reset() {
        set(initState());
    }

    function getStore() {
        let items = {};
        subscribe((store) => (items = store))(); // immediately unsubscribe
        return items;
    }

    function addUpload(uploadObject) {
        const currentStore = getStore();
        if (currentStore.uploads) {
            update((store) => {
                store.uploads.push(uploadObject);
                return store;
            });
        } else {
            set({
                uploads: [uploadObject],
                processed: 0,
                failed: 0,
            });
        }
    }

    function dismissItem(uploadObject) {
        const currentStore = getStore();
        const index = currentStore.uploads.indexOf(uploadObject);
        if (index > -1) {
            currentStore.uploads.splice(index, 1);
            if (currentStore.uploads.length === 0) {
                reset();
            } else {
                update((store) => {
                    store.uploads = currentStore.uploads;
                    store.processed--;
                    return store;
                });
            }
        }
    }

    function incrementProcessed() {
        update((store) => {
            store.processed += 1;
            return store;
        });
    }

    function incrementFailed() {
        update((store) => {
            store.failed += 1;
            return store;
        });
    }

    return {
        addUpload,
        dismissItem,
        getStore,
        incrementProcessed,
        incrementFailed,
        reset,
        subscribe,
    };
})();

// https://developers.google.com/web/updates/2018/07/page-lifecycle-api#the-beforeunload-event
const _beforeUnloadListener = () => {
    // If you wanted to cancel the event, you would:
    // {
    //     event.preventDefault(); // Cancels the event
    //     // Some browsers require returnValue set, and others a return value
    //     return (event.returnValue = 'Are you sure you want to exit?');
    // }

    // Log if we have pending uploads
    if (uploads.size > 0) {
        trackEventWithDataType({
            category: categories.upload,
            action: actions.fileUploadAbandon,
            label: '_beforeUnloadListener', // label is required, else event is not sent!
            value: uploads.size,
        });
    }
};

const _params = ['beforeunload', _beforeUnloadListener, { capture: true }];

const _fullPath = async (uploadObject, rest) => {
    const uploadInfo = await uploadObject.info();
    let parentFolder;
    if (uploadObject.uploadType === 'dropbox') {
        parentFolder = uploadInfo[0].parentFolder;
    } else {
        parentFolder = uploadInfo.parentFolder;
    }

    // All parents should be in memory now
    let pathHere = '';
    while (parentFolder) {
        pathHere = '/' + parentFolder.itemInfo.name + pathHere;
        parentFolder = parentFolder.parent;
    }

    return pathHere + '/' + rest;
};

export const addUploadInfo = async (uploadObject) => {
    // First upload?
    if (uploads.size === 0) {
        // @ts-ignore
        addEventListener(..._params);
    }

    // uploadObject.path contains the source path to the file to upload.
    // At this point, we haven't created the intermediate folders,
    // so append them here, including the filename.
    const fullPath = await _fullPath(uploadObject, uploadObject.path);

    // Should not occur
    if (uploads.has(fullPath)) {
        trackEventWithDataType({
            category: categories.upload,
            action: actions.fileUploadDuplicate,
            label: fullPath,
        });
    }
    uploadStore.addUpload(uploadObject);
    uploads.set(fullPath, uploadObject);
};

export const removeUploadInfo = async (uploadObject) => {
    // Intermediate folders have now been created, so we only need to
    // append the filename
    const fileName = uploadObject.path.split('/').pop();
    const fullPath = await _fullPath(uploadObject, fileName);
    uploads.delete(fullPath);
    uploadStore.incrementProcessed();
    // Last upload?
    if (uploads.size === 0) {
        // @ts-ignore
        removeEventListener(..._params);
    }
};
