import { writable, derived, get } from 'svelte/store';
import { clearCache, DriveError } from '../../rest.sdks';
import {
    getDriveInfo,
    removeAllCollaborators,
} from '../../rest.sdks/collection';
import { goto, goToHome } from '../navigation';
import { clearState, currentFolder } from './active.record';
import { identifyUserVoice } from '../analytics/uservoice';
import { identifyLaunchDarkly } from '../launchdarkly';
import { i18n } from '../../i18n';
import { forgedm } from '../../rest.sdks/request';
import store from 'store2';
import {
    trackEvent,
    trackDimension,
    categories,
    actions,
} from '../../providers/analytics/ga';
import { firstLetterLowerCase } from '../utils';
import { systemOutage } from '../feature.flag';
import { loginWithRedirect, logoutWithRedirect } from '../pkce-auth';

export const authStages = {
    checkingSignin: 'checkingSignin',
    signedIn: 'signedIn',
    signedOut: 'signedOut',
};

// Custom store to track custom dimensions
const _trackStore = (fcn, seed) => {
    let _value = seed;
    const { set: _set, subscribe } = writable(_value);

    return {
        set: (value) => {
            _value = value;
            trackDimension(fcn(value));
            _set(value);
        },
        subscribe, // must expose
        getValue: () => _value,
    };
};

export const authStage = _trackStore(
    (userLoggedIn) => ({ userLoggedIn }),
    authStages.checkingSignin
);
export const profile = writable(null);
export const gEntitled = writable(null);
export const gFusionUser = writable(false);
export const gBimUser = writable(false);
export const gDriveInfoResponseTime = writable(null);
// Custom store to manage OOD banner
export const showOpenOnDesktopBanner = (() => {
    const { set: _set, subscribe } = writable(null);
    return {
        subscribe,
        set: (show) => {
            const userId = get(profile).userId;
            const userStorage = store(userId) || {};
            if (!show) {
                store.add(userId, {
                    hasSeenOpenOnDesktopBanner: !show,
                });
            }
            _set(!userStorage.hasSeenOpenOnDesktopBanner);
        },
    };
})();
export const gTenantMigrationLock = (() => {
    const { set: _set, subscribe } = writable(null);
    return {
        subscribe,
        set: (tenantMigrationLock) => {
            const userId = get(profile).userId;
            const userStorage = store(userId) || {};
            const lockAlreadyExists = userStorage.tenantMigrationLock || false;
            let interval;
            let lockTimestamp = userStorage.lockTimestamp || null;

            const releaseLock = () => {
                _set(false);
                gEntitled.set(true);
                // Clear local storage variables
                delete userStorage.tenantMigrationLock;
                delete userStorage.lockTimestamp;
                store.set(userId, userStorage);
                clearInterval(interval);
            };

            const enableReadOnlyMode = () => {
                _set(true);
                gEntitled.set(false);
            };

            const checkForRemoval = () => {
                const _currentTime = new Date().getTime();
                const threshold = 5 * 60 * 1000; // 5 Minutes
                const lockExpired = _currentTime - lockTimestamp >= threshold;
                if (lockExpired) {
                    releaseLock();
                    return true;
                }
                return false;
            };

            if (!tenantMigrationLock) {
                releaseLock();
            } else if (lockAlreadyExists) {
                enableReadOnlyMode();
                // Special case: if user sees the lock banner and closes the tab/browser
                // comes back again to Drive, we check for removal.
                const isLockRemoved = checkForRemoval();
                if (!isLockRemoved) {
                    interval = setInterval(checkForRemoval, 30000);
                }
            } else if (tenantMigrationLock) {
                lockTimestamp = new Date().getTime();
                store.add(userId, {
                    tenantMigrationLock,
                    lockTimestamp, // Set timestamp when lock was enabled
                });
                enableReadOnlyMode();
                interval = setInterval(checkForRemoval, 30000);
            }
        },
    };
})();
export const gDriveInfo = _trackStore(
    (value) => ({
        driveLicenseValid: value
            ? value.driveLicenseValid
                ? 'true'
                : 'false'
            : '?',
    }),
    null
);
export const gInitiationError = writable(null);
export const gPrivateShares = writable(null);
export const gHasSystemOutage = writable(null);

// Either entitled, or showing others content
export const gPermitted = derived(
    [currentFolder, gEntitled, gTenantMigrationLock],
    ([_currentFolder, _gEntitled, _gTenantMigrationLock], set) => {
        let displayingPrivateData =
            _currentFolder && _currentFolder.isPrivateShared;
        // TODO: Remove gTenantMigrationLock condition once we're done with WIPNext
        set(_gEntitled || (displayingPrivateData && !_gTenantMigrationLock));
    },
    false
);

export const authenticated = derived(
    authStage,
    ($authStage) => $authStage === authStages.signedIn
);

export const checkingSignin = derived(
    authStage,
    ($authStage) => $authStage === authStages.checkingSignin
);

export const signedOut = derived(
    authStage,
    ($authStage) => $authStage === authStages.signedOut
);

export const userName = derived(profile, ($profile, set) => {
    if (!$profile) return '';
    set(i18n.displayFullName($profile));
});

export const signIn = async () => {
    await loginWithRedirect();
};

export const signOut = () => {
    logoutWithRedirect();
};

export const userSignedIn = (userProfile) => {
    clearState();
    clearCache();
    setupUser(userProfile);
};

export const userSignedOut = (loginRequired) => {
    clearState();
    clearCache();
    profile.set(null);
    gDriveInfo.set(null);
    gDriveInfoResponseTime.set(null);
    authStage.set(authStages.signedOut);

    if (loginRequired) {
        signIn();
    }
};

const checkOtherHubs = async () => {
    const path = 'hubs';
    // ForgeDM_Get_Hubs
    const response = await forgedm(path);
    const hubs = response.data;
    hubs.forEach((hub) => {
        const hubType = hub.attributes.extension.type;
        if (hubType.includes('core') || hubType.includes('a360')) {
            gFusionUser.set(true);
        } else if (hubType.includes('bim360')) {
            gBimUser.set(true);
        }
    });
};

const checkTenantLock = ({ userId }) => {
    const userStorage = store(userId) || {};
    if (userStorage.tenantMigrationLock) {
        gTenantMigrationLock.set(true);
    }
};

// Remove DriveShares (Collaborators)
const removeDriveShares = async ({ userId, collectionId }) => {
    const key = 'driveShares';
    const userStorage = store(userId) || {};
    let hadCollaborators;
    // If we haven't checked, or we have Drive Shares, check again...
    if (
        !Object.prototype.hasOwnProperty.call(userStorage, key) ||
        userStorage[key]
    ) {
        hadCollaborators = await removeAllCollaborators(collectionId);
        // Successfully removed all Collaborators
        store.add(userId, { [key]: false });
    } else {
        hadCollaborators = false;
    }

    trackEvent({
        category: categories.share,
        action: actions.status,
        label: hadCollaborators.toString(),
        detail: collectionId, // So we can isolate repeat offenders
    });
};

const setupUser = async (userProfile) => {
    // Rename some properties to have consistent names across the app
    const properties = ['FirstName', 'LastName', 'UserId', 'Email'];
    properties.forEach((property) => {
        if (userProfile[property]) {
            const camelCaseName =
                property === 'Email' // Special Case
                    ? firstLetterLowerCase(`${property}Id`)
                    : firstLetterLowerCase(property);
            userProfile[camelCaseName] = userProfile[property];
            delete userProfile[property];
        }
    });

    const urlParams = new URLSearchParams(location.search);
    const returnPath = store.get('returnPath');
    const picker = urlParams.get('picker') === 'true';
    if (returnPath) {
        store.remove('returnPath');
        goto(returnPath);
    } else if (picker) {
        // no-op - do not change the path
    } else if (['/', '/login', '/oauth/callback'].includes(location.pathname)) {
        goToHome();
    }

    try {
        // Setup the profile before calling getDriveInfo() which might throw
        profile.set(userProfile);

        // Drive_Get_Drive_Info
        const { attributes } = await getDriveInfo(true);

        // Spawn this and ignore exceptions
        removeDriveShares(attributes);

        gDriveInfo.set(attributes);
    } catch (error) {
        // Handle case where Drive Hub initialization fails
        gInitiationError.set(error);
        DriveError.logError(actions.initializationFailed, error);
    }

    identifyLaunchDarkly(userProfile).then(() => {
        // Read user-level FF here
    });
    gHasSystemOutage.set(systemOutage());
    checkTenantLock(userProfile);
    identifyUserVoice(userProfile);
    checkOtherHubs();
};
